import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function SubscriptionsList({subscriptions, count, type}) {
    const rows = []
        if ( type === 'short' ) {
            subscriptions.slice(0,5).forEach( res => {
                rows.push(res)
            })
        } else {
            subscriptions.forEach( res => {
                rows.push(res)
            })
        }
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow><TableCell colSpan={5}>
                            <Typography variant="h6">Subscriptions
                                { (type === 'short') ? <Typography  color="warning.main" paddingLeft={3} variant="caption" component={Link} to="/company/subscriptions">view all</Typography> : "" }
                            </Typography></TableCell></TableRow>
                        <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell align="right">Service Type</TableCell>
                            <TableCell align="right">Tenant Name</TableCell>
                            <TableCell align="right">Service Level</TableCell>
                            <TableCell align="right">Retention</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            component={Link}
                            to={`/company/subscriptions/${row.id}`}
                            >
                            <TableCell component="th" scope="row">
                            {row.service.service_name}
                            </TableCell>
                            <TableCell align="right">{row.sub_cloud ? row.sub_cloud.service : <Typography color="grey" variant="caption">none</Typography>}</TableCell>
                            <TableCell align="right">{row.sub_cloud ? row.sub_cloud.name : <Typography color="grey" variant="caption">none</Typography>}</TableCell>
                            <TableCell align="right">{row.subscription.level}</TableCell>
                            <TableCell align="right">{row.subscription.retention_days /365} years</TableCell>
                            </TableRow>
                        ))}
                    {( type === "short" && count > 5) ? <TableCell colSpan={5}>...</TableCell> : ""}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={5}>
                                <Button
                                    color="darkgreen"
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    component={Link}
                                    to="/company/subscriptions/add"
                                >
                                    <Typography >Add Subscription</Typography>
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>


        );
}
