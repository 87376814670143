import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block, DeleteForever, MarkChatRead } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Checkbox from '@mui/material/Checkbox';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function MessageRulesList({rules}) {
    /* 
        rules.forEach((val) => {
            console.log(val.actions)
            console.log(val.conditions)
            console.log(val.exceptions)
        })
        for (const [key, value] of Object.entries(object1)) {
            console.log(`${key}: ${value}`);
        }
    */
    const [actionNone, setActionNone] = useState(true)
    function printJSON(data) {
        for (const key in data) {
            if ( key === "moveToFolder") {
                if (data[key].length > 30) {
                    data[key] = data[key].substring(0, 60)+"..."
                    console.log(`${key}: ${data[key]}`);
                }
            }
        }
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small" id="item-table">
                <TableHead>
                    
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell align="left">Rule Name</TableCell>
                        <TableCell align="left">Discovered</TableCell>
                        <TableCell align="left">Rule ID</TableCell>
                        <TableCell align="left">Enabled</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rules.map((row) => (
                        <>
                            <TableRow
                                key={row.id}
                                >
                                <TableCell component="th" scope="row"><Typography variant="body2" style={{ fontWeight: 'bold'}}>{row.user.display_name}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.display_name}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.discovered}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.rule_id}</Typography></TableCell>
                                <TableCell align="left">
                                    <Typography variant='caption' color="grey.600" >
                                        { row.enabled 
                                        ? <Checkbox size="small" id={`check-${row.id}`} checked />
                                        : <Checkbox size="small" id={`check-${row.id}`}  />
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={row.id}>
                                <TableCell colSpan={5} align="left">Conditions</TableCell>
                            </TableRow>
                            <TableRow key={row.id}>
                                <TableCell colSpan={5} align="left"><Typography variant="caption">{printJSON(row.conditions)}</Typography></TableCell>
                            </TableRow>
                            <TableRow key={row.id}>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                            <TableRow key={row.id}>
                                <TableCell colSpan={5} align="left"><Typography variant="caption">{printJSON(row.actions)}</Typography></TableCell>
                            </TableRow>
                            
                            <TableRow key={row.id}>
                                <TableCell colSpan={5} align="left">Exceptions</TableCell>
                            </TableRow>
                            <TableRow key={row.id}>
                                <TableCell colSpan={5} align="left"><Typography variant="caption">{printJSON(row.exceptions)}</Typography></TableCell>
                            </TableRow>
                            <TableRow key={row.id} style={{ height: '50px', borderBottom: "2px solid black" }}>
                                <TableCell colspan={5}  ></TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>


    );
}
