import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block } from '@mui/icons-material';


function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function UserList({users}) {
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toISOString().split('T')[0]
      }
    const rows = []
       users.forEach( res => {
        rows.push(res)
       })
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow><TableCell colSpan={5}>
                            <Typography variant="h6">Users</Typography></TableCell></TableRow>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align="right">First</TableCell>
                            <TableCell align="right">Last</TableCell>
                            <TableCell align="right">Date Created</TableCell>
                            <TableCell align="center">Is Primary?</TableCell>
                            <TableCell align="right">Last Login</TableCell>
                            <TableCell align="right">Failed Logins</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row"><Typography variant='caption'>{row.email}</Typography></TableCell>
                        <TableCell align="right"><Typography variant='caption'>{row.first_name}</Typography></TableCell>
                        <TableCell align="right"><Typography variant='caption'>{row.last_name}</Typography></TableCell>
                        <TableCell align="right"><Typography variant='caption'>{formatClock(row.date_joined)}</Typography></TableCell>
                        <TableCell align="center"><Typography variant='caption'>{row.is_customer_primary === true ? <Check  sx={{ fontSize: "small "}} /> : <Block sx={{ fontSize: "small "}}/>}</Typography></TableCell>
                        <TableCell align="right"><Typography variant='caption'>{row.last_login ? formatClock(row.last_login) : "Never"}</Typography></TableCell>
                        <TableCell align="right"><Typography variant='caption'>{row.logins_failed}</Typography></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={7}>
                            <Button
                                color="darkgreen"
                                type="submit"
                                fullWidth
                                variant="outlined"
                                size="small"
                                component={Link}
                                to="/company/users/add"
                            >
                                <Typography >Add User</Typography>
                            </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>


        );
}
