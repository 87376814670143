import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, CircularProgress } from '@mui/material';
import { useNavigate, Link, useParams, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Microsoft365Menu from 'components/Dashboards/SideMenus/Microsoft365Menu';
import MailboxFoldersList from '../Microsoft365/MailboxFoldersList'

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function MailboxFolders() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [folders, setFolders] = useState([]);
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const { user_id } = useParams();

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    
    useEffect(() => {
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL + 'azure/mailbox/folder-list/'+user_id, {headers: authHeader() }).then( res => res.json()),
        ])
        .then( (res) => {
            setCloudkeys(res[1]) 
            setFolders(res[2])
            setLoading(false)
        })

    }, []);
    document.title = 'Exchange 365 Mailbox Folders';

      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {

        return (
            <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/azure" ><Typography color="darkgreen.main">Microsoft 365</Typography></Link>
                                <Typography color="text.primary">Mailbox Folder List</Typography>
                            </Breadcrumbs>
                        </Grid>
                       
                        <Grid item xs={12} md={9} lg={9}>
                            
                            {folders.length > 0 ?
                            <Grid item xs={12} md={12} mb={2} >
                               
                                    <MailboxFoldersList folders={folders} />
                            </Grid> : "" }

                         
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} maxHeight={"220px"}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                            >
                                <Microsoft365Menu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>
            </ThemeProvider>
        );
    }
}
