import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import AzureCloudSelect from '../AzureCloudSelect';
import colors from 'assets/theme/base/colors';
import Microsoft365Menu from 'components/Dashboards/SideMenus/Microsoft365Menu';
import SecureScore from './SecureScore';
import UserCount from './UserCountCard';
import MailboxUsageChart from './Charts/MailboxUsage';
import MailboxStorage from './Charts/MailboxStorage';
import EmailActivity from './Charts/EmailActivity';
import TeamsActivity from './Charts/TeamsActivity';
import OneDriveActivity from './Charts/OneDriveActivity';
import SharePointFileActivity from './Charts/SharePointFileActivity';
import SharePointUsage from './Charts/SharePointUsage';
import SharePointStorage from './Charts/SharePointStorage';
import Button from '@mui/material/Button';
import moment from "moment";
import dayjs from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


var newDate = new Date()

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none' 
    },
  },
 });

export default function Microsoft365Home() {
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [fromTime, setFromTime] = useState(dayjs(newDate.setDate(newDate.getDate() -7)));
  const [toTime, setToTime] = useState(dayjs(new Date()));
  const [cloudKeys, setCloudkeys] = useState([]);
  const [cloudKey, setCloudKey] = useState(localStorage.getItem("cloud_key_azure"));
  const [hasAzureServices, setHasAzureServices] = useState(false);
  const [mailboxUsageStorage, setMailboxUsageStorage] = useState([]);
  const [mailboxUsageCounts, setMailboxUsageCounts] = useState([]);
  const [mailboxUsageDetail, setMailboxUsageDetail] = useState([]);
  const [emailActivityCounts, setEmailActivityCounts] = useState([]);
  const [teamsActivityCounts, setTeamsActivityCounts] = useState([]);
  const [oneDriveActivityCounts, setOneDriveActivityCounts] = useState([]);
  const [sharePointFileCounts, setSharePointFileCounts] = useState([]);
  const [sharePointUsageCounts, setSharePointUsageCounts] = useState([]);
  const [sharePointUsageStorage, setSharePointUsageStorage] = useState([]);
  const [secureScore, setSecureScore] = useState();
  const [selectedKey, setSelectedKey] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const time_from = searchParams.get("from")
  const time_to = searchParams.get("to")
  const nav = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  function updateCloudKey() {
    fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
    .then( (res) => {
      localStorage.setItem("cloud_key_azure", res.id)
      setCloudKey(res.id)
      // localStorage.setItem("cloud_key_azure", )
    })
    .catch( err => {
      console.log(err)
    })
  }
  function redirectOnLogout() {
    localStorage.removeItem("token");
    nav("/sign-in")
  } 
  
  useEffect(() => {
    document.title = 'Microsoft 365 Dashboard';
    setSelectedKey(localStorage.getItem("cloud_key_azure"))
    Promise.all([
      fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
        if ( res.status === 401 ) { 
            redirectOnLogout() 
        }
      }),
      fetch(API_URL + 'dashboard/services', {headers: authHeader()}).then( result => {
        if (result.status === 200) {
          Promise.all([
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then( res => res.json() ),
            fetch(API_URL + 'azure/mailbox/usage-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/mailbox/usage-storage?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/mailbox/usage-detail?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/email/activity-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/teams/activity-user-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/onedrive/activity-user-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/sharepoint/activity-file-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/sharepoint/activity-user-counts?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/sharepoint/site-usage-storage?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/secure-score?cloud_key='+cloudKey, {headers: authHeader() }).then( res => res.json()),
          ])
          .then( (res) => {

            setCloudkeys(res[0]) 
            setMailboxUsageCounts(res[1]) 
            setMailboxUsageStorage(res[2])
            setMailboxUsageDetail(res[3])
            setEmailActivityCounts(res[4])
            setTeamsActivityCounts(res[5])
            setOneDriveActivityCounts(res[6])
            setSharePointFileCounts(res[7])
            setSharePointUsageCounts(res[8])
            setSharePointUsageStorage(res[9])
            setSecureScore(res[10])
            setLoading(false)
            // setDateLoading(false)
            setHasAzureServices(true)
          })
          .catch( err => {
            updateCloudKey()
            setLoading(false)
          } )
        } else {
          setLoading(false)
        }
      })
    ])
  }, [searchParams, cloudKey]);

  const updateDate = (() => {
    setButtonLoading(true)
    if ( fromTime.unix() > toTime.unix()) {
      setError(true)
      setErrorMessage("From date must be less than To date")
    } else {
      setSearchParams({from: fromTime.unix(), to: toTime.unix()})
    }
    
    setButtonLoading(false)
  })


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };


  if ( loading === true ) {
    return (
        <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
    )
  } else {
      return (
        <ThemeProvider theme={defaultTheme}>
          <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
            }}
            >
              <Grid container sx={{ mb: 10 }}>
                {/* Top row, breadcrumbs and tenant select */}
                <Grid container spacing={3} sx={{ mt: 4, mb: 4 }}>
                  <Grid item xs={12} md={9} mb={3}>
                    <Breadcrumbs aria-label="breadcrumb" >
                            <Link to="/app" ><Typography color="darkgreen.main">Home</Typography></Link>
                            <Link to="/azure"><Typography color="darkgreen.main">Azure</Typography></Link>
                            <Typography color="darkgreen.main">MS 365 Home</Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                  </Grid>
                </Grid>
                {/* end of top row */}
                <Grid container spacing={3} >
                  {/* Main body for content */}
                  <Grid item xs={12} md={9} >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <SecureScore score={secureScore} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <UserCount users={secureScore} />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <MailboxUsageChart data={mailboxUsageCounts} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <MailboxStorage data={mailboxUsageStorage} />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <EmailActivity data={emailActivityCounts} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <TeamsActivity data={teamsActivityCounts} />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <OneDriveActivity data={oneDriveActivityCounts} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <SharePointFileActivity data={sharePointFileCounts} />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <SharePointUsage data={sharePointUsageCounts} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} mt={2} >
                        <Paper
                          sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                          }}
                        >
                          <SharePointStorage data={sharePointUsageStorage} />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* End main body */}
                  {/* Start right column menu */}
                  <Grid item xs={12} md={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} >
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                          >
                            <Typography variant="caption" sx={{ mb: 2 }}>Graph date range</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker label="Select start date" disableFuture onChange={setFromTime} />
                              <DatePicker label="Select end date" disableFuture onChange={setToTime} sx={{ mt: 2 }} />
                            </LocalizationProvider>
                            {buttonLoading ? <Button sx={{ mt: 2}} variant="contained" size="small" >Loading</Button> : <Button sx={{ mt: 2}} variant="outlined" size="small" onClick={updateDate}>Update</Button> }
                          </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>

              </Grid>
          </Box>
          <Snackbar open={error} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      )
  }
};
