import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, Button, CircularProgress,  } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from '../SideMenus/CompanyMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';


const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

export default function SubscriptionDelete() {
    const [loading, setLoading] = useState(true);
    const [subService, setSubService] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [confirmed, setConfirmed] = useState(false);
    const { subscription_id } = useParams();
    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'subs/services/'+subscription_id, {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'subs/user-info', {headers: authHeader() }).then(res => res.json()),
        ])
        .then( (res) => {
          setSubService(res[1]);
          setUserInfo(res[2]);
          setLoading(false)
          if ( subServices.services.length === 0 ) {
            nav("/company/services/add")
          }
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);

      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }
      console.log(confirmed)
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
            <ThemeProvider theme={defaultTheme}>
            <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
            }}
            >
            <Container sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mb={3}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                        <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                        <Link to="/company/subscriptions" ><Typography color="text.primary">Subscriptions</Typography></Link>
                        <Typography color="text.primary">{subService.sub_cloud.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    {userInfo.is_customer_primary === true ? 
                        <Card>
                            <CardHeader title="Delete a subscription" />
                            
                            <CardContent>
                                <Typography variant="caption">This will permanently delete the data for tenant <Typography variant="caption" sx={{ fontWeight: "bold"}} component="span" color="darkgreen.main">{subService.sub_cloud.name} </Typography> including:</Typography>
                                <List dense sx={{ ml: 5, listStyleType: 'disc'}}>
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary="Management Logs" />
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary="Sign-in Logs" />
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary="User lists" />
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary="Graph data" />
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                                        <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary="Custom trend data" />
                                    </ListItem>
                                </List>
                                <Typography variant="caption"><Checkbox onChange={() => setConfirmed(value => !value)} size="small" color="darkgreen" /> I understand that this cannot be reversed and all related data for this tenant will be deleted.</Typography>
                            </CardContent>
                            <CardActions>
                                { confirmed ? <Button component={Link} to={`/company/subscriptions/${subService.id}/delete/confirm`} variant="contained">Delete Tenant and Data</Button> : <Button disabled>Delete Tenant and Data</Button> }
                            </CardActions>
                        </Card>
                    : 
                        <Card>
                            <CardHeader title="Unauthorized" />
                            
                            <CardContent>
                                Only primary account holders can delete tenant subscriptions.Please contact your Trawl AI administrator.
                            </CardContent>
                            <CardActions>
                                <Button size="md" variant={Link} href={`/company/subscriptions/${subService.id}`}>Go back</Button>
                            </CardActions>
                        </Card>
                }
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: "100%",
                    }}
                    >
                        <CompanyMenu />
                    </Paper>
                </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </ThemeProvider>
        );
    }
}
