import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, Button, CircularProgress, } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from '../SideMenus/CompanyMenu';

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function Subscription() {
    const [loading, setLoading] = useState(true);
    const [subService, setSubService] = useState([]);
    const { subscription_id } = useParams();
    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'subs/services/'+subscription_id, {headers: authHeader() }).then(res => res.json()),

        ])
        .then( (res) => {
          setSubService(res[1]);
          setLoading(false)
          if ( subServices.services.length === 0 ) {
            nav("/company/services/add")
          }
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);

      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }

      console.log(subService)
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
          <Box
          component="main"
          sx={{
              backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
          }}
          >
            <Container sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mb={3}>
                  <Breadcrumbs aria-label="breadcrumb" >
                        <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                        <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                        <Link to="/company/subscriptions" ><Typography color="text.primary">Subscriptions</Typography></Link>
                        <Typography color="text.primary">{subService.sub_cloud.name}</Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} >
                                        <Typography variant="h6">Subscription</Typography>
                                    </TableCell>
                                    {/* <TableCell align="right">
                                        <Link to={`/company/subscriptions/${subscription_id}/delete`}><Typography color="darkgreen.main">Delete</Typography></Link>
                                    </TableCell> */}
                                </TableRow>
                                <TableRow>                                 
                                <TableCell align="left">Tenant</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell colSpan={4} align="left">Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                key={subService.service.service_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{subService.sub_cloud.name}</TableCell>

                                    <TableCell component="th" scope="row">{subService.service.service_name}</TableCell>
                                    <TableCell colSpan={4} align="left">{subService.service.service_name_description}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow><TableCell colSpan={5}></TableCell></TableRow>
                                <TableRow>
                                    <TableCell>Level</TableCell>
                                    <TableCell colSpan={3} align="left">Description</TableCell>
                                    <TableCell align="left">Retention</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{subService.subscription.level}</TableCell>
                                    <TableCell colSpan={3}>{subService.subscription.description}</TableCell>
                                    <TableCell>{subService.subscription.retention_days /365} years</TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell align="right" colSpan={5}>
                                    <Button
                                        color="darkgreen"
                                        type="submit"
                                        fullWidth
                                        variant="text"
                                        size="small"
                                        component={Link}
                                        to="/company/add-service"
                                    >
                                        <Typography >Add Service</Typography>
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Paper
                  sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                  }}
                  >
                      <CompanyMenu />
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
              </Container>
          </Box>

        );
    }
}
