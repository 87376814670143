import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Breadcrumbs, Card, CardHeader, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import CompanyMenu from '../SideMenus/CompanyMenu';
import NotificationsList from './NotificationsList';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AzureCloudSelect from '../Azure/AzureCloudSelect';

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });
function formatClock(date) {
        const clock = new Date(date)
        return clock.toISOString().split('T')[0]
      }
export default function UserProfile() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState()
  const [notifications, setNotifications] = useState([]);
  const nav = useNavigate();
  const [numPages, setNumPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState();
  const [hasPrev, setHasPrev] = useState();
  const [prevPage, setPrevPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const [urlQuery, setUrlQuery] = useState();
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [searchParams, setSearchParams] = useSearchParams()
  const page= searchParams.get("page")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedKey, setSelectedKey] = useState();
  const [cloudKeys, setCloudkeys] = useState([]);

  var cloudKey = localStorage.getItem("cloud_key_azure")
  if ( cloudKey === null ) {
    fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
    .then( (res) => {
      localStorage.setItem("cloud_key_azure", res.id)
      // localStorage.setItem("cloud_key_azure", )
    })
    .catch( err => {
      console.log(err)
    })
  }

  function redirectOnLogout() {
      localStorage.removeItem("token");
      nav("/sign-in")
  }
    
  function formatClock(date) {
    const clock = new Date(date)
    return clock.toISOString().split('T')[0]
  }

  const submitPasswordChange = (e) => {
    setAlert(false)
    setLoading(true)
    const data = new FormData(e.currentTarget);

    let password1 = data.get('password')
    let password2 = data.get('password-confirm')
    e.preventDefault()
    if (password1 === password2 ) {
        fetch(API_URL + "subs/password-change",{ method: 'POST', headers: authHeader(), body: JSON.stringify({"password_new": password1} )} )
        .then( ( res) => {
          if (res.status === 200 ) {
            setSuccess(true)
            setSuccess("Password updated")
            setAlert(false)
          } else {
            setAlertText("Non OK response from server")
            setAlert(true)
          }
        })
        .catch( err => {
          setAlertText(err.message)
        setAlert(true)
        })
    } else {
      setAlertText("Passwords don't match")
      setAlert(true)
      
    }
    setLoading(false)
  }

  useEffect(() => {
    Promise.all([
      fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
        if ( res.status === 401 ) { 
            redirectOnLogout() 
        }
      }),
      fetch(API_URL + 'subs/user-info', {headers: authHeader() }).then( res => res.json()),
      fetch(API_URL + 'subs/account/notifications', {headers: authHeader() }).then( res => res.json()),
      fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json())

    ])
    .then( (res) => {
      setUserInfo(res[1]);
      setNotifications(res[2]["data"])
      setNumPages(res[2]["num_pages"])
      setHasNext(res[2]["has_next"])
      setHasPrev(res[2]["has_previous"])
      setCloudkeys(res[3])
      setLoading(false)
    })
    .catch( err => {
      setLoading(false)
      console.log("Error encountered in Promise: ", err)
    })
  }, [searchParams]);
  const onPageSubmit = (event, value) => {
    setLoading(true)
    setCurrentPage(value)
    setSearchParams({"page": value})
  }
  function ShowPagination() {
    return (
        <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
    )
  }
  function formatClock(date) {
    const clock = new Date(date)
    return clock.toLocaleDateString()
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  if ( loading === true ) {
      return (
          <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
      )
  } else {
        return (
          <ThemeProvider theme={defaultTheme}>
            <Modal
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                  backdrop: {
                  timeout: 500,
                  },
              }}
              aria-labelledby="password-reset"
              aria-describedby="password-reset-modal"
            >
              <Fade in={open}>
                <Box component="form" sx={style} onSubmit={submitPasswordChange}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Reset your password
                  </Typography>
                  <Grid container spacing={2}>
                    { alert ? 
                        <Grid item xs={12}><Alert severity='error'  action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}>{alertText}</Alert></Grid>
                        : ""}
                        { success ? 
                        <Grid item xs={12}><Alert severity='success'  
                        sx={{ mb: 2 }}>{success}</Alert></Grid>
                        : ""}
                        
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="password-confirm"
                            label="Confirm Password"
                            type="password"
                            id="password-confirm"
                            autoComplete="new-password"
                          />
                        </Grid>

                    {loading ?
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="darkgreen"
                        disabled
                      >
                        <Typography color="light.main"><CircularProgress color="dark" size="1rem" /></Typography>
                      </Button>
                    :
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="darkgreen"
                      >
                        <Typography color="light.main">Reset Password</Typography>
                      </Button>
                    }
                  </Grid>
                </Box>
              </Fade>
            </Modal>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Typography color="text.primary">Company</Typography>
                            </Breadcrumbs>
                        </Grid>                        
                        <Grid item xs={12} md={3}>
                            <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Card >
                                  <CardContent>
                                    <Grid container>
                                      <Grid item xs={12} md={8}>
                                        <Typography variant="h5">{userInfo.first_name} {userInfo.last_name}</Typography>
                                        <Typography variant="caption">{userInfo.email}</Typography>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          sx={{ mt: 3, mb: 2 }}
                                          color="darkgreen"
                                          onClick={handleOpen}>
                                            <Typography variant="caption" color="white.main">Reset Password</Typography>
                                          </Button>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12}>
                              <NotificationsList notifications={notifications} />
                              <Grid mt={5} >
                                {numPages > 0 ? <ShowPagination /> : "" }
                            </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: "280px",
                            }}
                            >
                                <CompanyMenu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>
              </ThemeProvider>
        );
    }
}
