export default function authHeader() {
    var header = new Headers();
    const token = localStorage.getItem('token');
    const key = JSON.parse(localStorage.getItem('cloud_key_azure'));
    if (token ) {
        header.append('Authorization', 'Bearer ' + token);
    }
    if (key) {
        header.append('AzureKey', key)
    }
    header.append('Content-type', 'application/json')
    return header
}