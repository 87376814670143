import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import trawlLogo from "assets/images/logos/trawl logo large 2022.png"
import Copyright from 'components/Copyright/Copyright';
import { Alert } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from 'components/Authentication/AuthenticationService';
import colors from "assets/theme/base/colors";

const defaultTheme = createTheme({
  palette: {...colors}
});

export default function SignIn() {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const registration = searchParams.get("registration")

  const handleSubmit = (event) => {
    
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    AuthService.login(data.get('email'), data.get('password')).then(
      (res) => {
        console.log(res)
        setLoginFailed(false)
        setLoading(false)
        nav("/azure/365")
      })
      .catch( (err) => {
        setLoginFailed(true)
        setLoading(false)
      })
    
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <img src={trawlLogo} alt="Trawl AI" width="450px" />
          <Typography component="h1" variant="h5" mt={2}>
            Sign in
          </Typography>
          {(loginFailed ? <Alert severity='error'>Login Failed. Please try again</Alert>: '')}
          {(registration === "success" ? <Alert severity='success'>Registration successful. Please log in.</Alert>: '')}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            { loading ?
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled
              >
                Loading
              </Button>
              :
              <Button
                color="darkgreen"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}

              >
                <Typography color="light.main">Sign In</Typography>
              </Button>
            }
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" color="darkgreen.main">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2" color="darkgreen.main">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}