import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Chart from 'components/Dashboards/Chart';
import Deposits from 'components/Dashboards/Deposits';
import Orders from 'components/Dashboards/Orders';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import { Outlet, useNavigate } from 'react-router-dom';
import colors from "assets/theme/base/colors";
import AzureSideMenu from "./AzureSideMenu";


const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });


export default function AzureLayout() {
   return (
    <ThemeProvider theme={defaultTheme}>
      <Container  maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item md={2} >
            <AzureSideMenu />
          </Grid>
          <Grid item md={10}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
   );
}
