import {useState, useEffect}  from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import colors from 'assets/theme/base/colors';


function renderScore(score, max) {
    let pct = score / max * 100
    pct = Math.round(pct).toFixed(2)
    if ( pct >= 80) {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="darkgreen.main">{pct}%</Typography>
        </Box>)
    }  else if ( pct <= 67 ) {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="error">{pct}%</Typography>
        </Box>)
    } else {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="warning">{pct}%</Typography>
        </Box>)
    }
    
  }

export default function SecureScore({score}) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (score) {
            setLoading(false)
        }
    })
    
    if ( loading === false ) {
        return (
            <Card sx={{ minHeight: "100%" }}>
                <CardContent>
                    <Typography gutterBottom>
                        Azure Secure Score
                    </Typography>
                    {renderScore(score["current_score"], score["max_score"])}
                    
                        
                </CardContent>
                <CardActions>
                    <Typography component={Link} to="/azure/secure-score" color="darkgreen.main" variant="caption">Learn More about Secure Score</Typography>
                </CardActions>
            </Card>
        )  
    }
}
