import {useState, useEffect}  from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';


export default function UserCount({users}) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (users) {
            setLoading(false)
        }
    })
    
    if ( loading === false ) {
        return (
            <Card sx={{ minHeight: "100%", padding: 2 }} >
                <TableContainer>
                <Table aria-label="secure-score-user-table">
                    <TableRow>
                    <TableCell>Licensed Users</TableCell>
                    <TableCell>Active Users</TableCell>
                    </TableRow>
                    <TableBody>
                    <TableRow>
                        <TableCell>{users["licensed_users"]}</TableCell>
                        <TableCell>{users["active_users"]}</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </Card>
        )  
    }
}
