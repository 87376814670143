import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import AzureCloudSelect from '../AzureCloudSelect';
import AzureManagementLogSelect from './AzureManagementLogSelect';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SharePointLogList from './ManagementLogLists/SharePointLogList';
import ExchangeLogList from './ManagementLogLists/ExchangeLogList';
import GeneralLogList from './ManagementLogLists/GeneralLogList';
import AzureADLogList from './ManagementLogLists/AzureADLogList';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Search } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const defaultTheme = createTheme({
    palette: {...colors},
});
var newDate = new Date()

export default function AzureManagementLogs() {
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [urlQuery, setUrlQuery] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const [fromTime, setFromTime] = useState(dayjs(newDate.setDate(newDate.getDate() -7)));
    const [toTime, setToTime] = useState(dayjs(new Date()));
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const [buttonLoading, setButtonLoading] = useState(false)

    const page = searchParams.get("page")
    const contentType = searchParams.get("contentType")
    if ( contentType === null ) {
        setSearchParams({contentType: "Audit.General"})
    }
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    function printJSON(data) {
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }

    const updateDate = (() => {
        setButtonLoading(true)
        if ( fromTime.unix() > toTime.unix()) {
            setError(true)
            setErrorMessage("From date must be less than To date")
        } else {
            // setSearchParams({from: fromTime.unix(), to: toTime.unix()})
            console.log(fromTime, toTime)
        }
        setButtonLoading(false)
    })
    
    useEffect(() => {
        setLoading(true)
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'azure/management/audit?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json())
        ])
        .then( (res) => {
            setLogs(res[1]["data"])
            setNumPages(res[1]["num_pages"])
            setHasNext(res[1]["has_next"])
            setHasPrev(res[1]["has_previous"])
            setCloudkeys(res[2]) 
            
            setLoading(false)
        })
       
    }, [searchParams]);
    document.title = 'Azure Management Logs';

    const ManagementLogList = () => {
        if ( contentType === "Audit.SharePoint") {
           return <SharePointLogList logs={logs} />
        }  else if ( contentType === "Audit.Exchange" ) {
            return <ExchangeLogList logs={logs}  />
        } else if ( contentType === "Audit.General" ) {
            return <GeneralLogList logs={logs} />
        } else {
            return <AzureADLogList logs={logs} />
        }
    }
    
    const clearSearch = () => {
        searchParams.delete("search")
        setSearchParams(searchParams)
    }

    const handleSearch = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        searchParams.set("search", data.get("searchbox"))
        setSearchParams(searchParams)
    }
    const SearchBox = () => {
        return (
                 <Box sx={{ display: 'flex', alignItems: 'flex-end', mr: 4 }} component="form" onSubmit={handleSearch}>
                    <Search sx={{ color: 'darkgreen.main', mr: 1, my: 0.5 }}  />
                    <TextField name="searchbox" id="searchbox" label="Search log" variant="standard" fullWidth />
                </Box>
        )
    }
    const LogSelect = () => {
        var log = contentType.split(".")
        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="primary">{log[1]}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Tenant"
                    onChange={selectLog}
                >
                    <MenuItem value="Audit.SharePoint" key="Audit.SharePoint" >SharePoint</MenuItem>
                    <MenuItem value="Audit.Exchange" key="Audit.Exchange" >Exchange</MenuItem>
                    <MenuItem value="Audit.General" key="Audit.General" >General</MenuItem>
                    <MenuItem value="Audit.AzureActiveDirectory" key="Audit.AzureActiveDirectory" >Azure AD</MenuItem>
                </Select>
                </FormControl>
            </Box>
        )
    }
    const selectLog = (event) => {
        searchParams.delete("page")
        setCurrentPage(1)
        setSearchParams({contentType: event.target.value})
    };

    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
    }

    const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        setSearchParams({"page": value, "contentType": contentType})
        setLoading(false)
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={9} >
                                <Breadcrumbs aria-label="breadcrumb" >
                                    <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                    <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                    <Typography color="text.primary">Azure Management Logs</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                            </Grid>

                            <Grid item xs={12} md={9} >
                                <Paper sx={{
                                        p: 2,
                                        mb: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <Grid container >
                                            <Grid item xs={12} md={8}>
                                                <SearchBox />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <LogSelect selected={contentType} />
                                            </Grid>
                                        </Grid>

                                        
                                </Paper>
                                <ManagementLogList />
                                
                                <Grid mt={5} >
                                    {numPages > 0 ? <ShowPagination /> : "" }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} maxHeight={"220px"}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} >
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: "100%",
                                            }}
                                        >
                                            <Typography variant="caption" sx={{ mb: 2 }}>Date range</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker label="Select start date" disableFuture onChange={setFromTime} />
                                                <DatePicker label="Select end date" disableFuture onChange={setToTime} sx={{ mt: 2 }} />
                                            </LocalizationProvider>
                                            {buttonLoading ? <Button sx={{ mt: 2}} variant="contained" size="small" >Loading</Button> : <Button sx={{ mt: 2}} variant="outlined" size="small" onClick={updateDate}>Update</Button> }
                                        </Paper>
                                    </Grid>
                                
                                </Grid>
                                <Grid item xs={12} >
                                    {searchParams.get("search") ? 
                                    <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: "100%",
                                    }}
                                    >
                                        <Chip label="Clear Search" onClick={clearSearch} />
                                    </Paper>
                                    : "" }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Box>

        );
    }
}
