import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { IconButton, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import { Group, Description, NotificationsActive, Directions, Flaky, Login } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const hoverColor="warning.main"

export default function Microsoft365Menu() {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', minHeight: "100%"}} >
      <nav aria-label="main mailbox folders">
        <List>
            {/*
            <ListSubheader component="div" id="nested-list-subheader">
                Configuration Summary
            </ListSubheader>
            <Divider />
            */}
            <ListItem
                sx={{
                    ":hover": {
                    backgroundColor: hoverColor,
                    color: "light.main"
                    }
                }}
                disablePadding
            >
                <ListItemButton component={Link} to="/azure/users"
                    dense>
                    <ListItemIcon sx={{ color: "inherit"  }}>
                        <Group color="inherit" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText  primary="Users" />
                </ListItemButton>
            </ListItem>
            
            <ListItem
                sx={{
                    ":hover": {
                    backgroundColor: hoverColor,
                    color: "light.main"
                    }
                }}
                disablePadding
            >
              <ListItemButton component={Link} to="/azure/sign-ins" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <Login  fontSize="small"/>
                </ListItemIcon>
                <ListItemText  primary="Sign Ins" />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                  ":hover": {
                  backgroundColor: hoverColor,
                  color: "light.main"
                  }
              }}
              disablePadding
            >
              <ListItemButton component={Link} to="/azure/365/message-rules" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <Directions  fontSize="small"/>
                </ListItemIcon>
                <ListItemText  primary="Message Rules" />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                  ":hover": {
                  backgroundColor: hoverColor,
                  color: "light.main"
                  }
              }}
              disablePadding
            >
              <ListItemButton role={undefined} component={Link} to="/azure/management-logs" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <Description fontSize="small" />
                </ListItemIcon>
                <ListItemText  primary="Management Logs" />
              </ListItemButton>
            </ListItem>

        </List>
      </nav>
     
    </Box>
  );
}