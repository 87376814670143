import { Routes, Route } from "react-router-dom"
import PrivateRoute from "components/Authentication/PrivateRoute";
import NotFound from "components/NotFound";
import { Navigate } from "react-router-dom";
import MS365MailboxUsageByUser from "./Azure/Microsoft365/MailboxUsageByUser";
import MS365EmailActivityByUser from "./Azure/Microsoft365/EmailActivityByUser";
import MS365OneDriveUsageByUser from "./Azure/Microsoft365/OneDriveUsageByUser";

export function ReportRoutes() {
    return (
            <Routes> 
                <Route path="/azure/ms365/mailbox-usage-by-user" element={<PrivateRoute><MS365MailboxUsageByUser /></PrivateRoute>}  />
                <Route path="/azure/ms365/email-activity-by-user" element={<PrivateRoute><MS365EmailActivityByUser /></PrivateRoute>}  />
                <Route path="/azure/ms365/onedrive-usage-by-user" element={<PrivateRoute><MS365OneDriveUsageByUser /></PrivateRoute>} />
            </Routes>

    )
}