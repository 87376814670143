import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Badge } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Tooltip from '@mui/material/Tooltip';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function ServicesList({services}) {
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();

    
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleString()
      }
    const rows = []
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="alert-table">
                    <TableHead>
                        <TableRow><TableCell colSpan={5}>
                            <Typography variant="h6">Available Service</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Service Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {services.map((row) => (
                            <TableRow
                            key={row.id}
                            id={`row-`+row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: "none"}}
                            >
                                <TableCell component="th" scope="row"><Typography variant="caption">{row.service_name}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.service_name_short}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.service_name_description}</Typography></TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>


        );
}
