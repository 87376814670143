import { Routes, Route, Navigate } from "react-router-dom"
import PrivateRoute from "components/Authentication/PrivateRoute";
import Services from "./Services";
import Company from "./Company";
import Users from "./Users";
import Alerts from "./Alerts";
import Items from "./Items";
import Triggers from "./Triggers";
import NotFound from "components/NotFound";
import Subscriptions from "./Subscriptions";
import Subscription from "./Subscription";
import SubscriptionAddHome from "./SubscriptionAdd/SubscriptionAddHome";
import SubscriptionAddAzureCallBack from "./SubscriptionAdd/SubscriptionAddAzureCallBack";
import SubscriptionAddSuccess from "./SubscriptionAdd/SubscriptionAddSuccess";
import SubscriptionDelete from "./SubscriptionDelete";
import SubscriptionDeleteConfirmation from "./SubscriptionDeleteConfirmation";
import UserProfile from "./UserProfile";
import UserAdd from "./UserAdd";


export function CompanyRoutes() {
    return (
            <Routes> 
                <Route path="/services" element={<Services />} />
                <Route path="/Subscriptions" element={<Subscriptions />} />
                <Route path="/subscriptions/:subscription_id" element={<Subscription />} />
                <Route path="/subscriptions/:subscription_id/delete" element={<SubscriptionDelete />} />
                <Route path="/subscriptions/:subscription_id/delete/confirm" element={<SubscriptionDeleteConfirmation />} />
                <Route path="/subscriptions/add" element={<SubscriptionAddHome />} />
                <Route path="/subscriptions/azure-callback" element={<SubscriptionAddAzureCallBack />} />
                <Route path="/subscriptions/add-success" element={<SubscriptionAddSuccess />} />
                <Route path="/profile" element={<Company />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/add" element={<UserAdd />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/items" element={<Items />} />
                <Route path="/triggers" element={<Triggers />} />
                <Route path="/user-profile" element={<UserProfile />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>

    )
}