import { useState, useEffect, Fragment } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link as ReactLink } from 'react-router-dom';
import Link  from '@mui/material/Link' ;
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from 'components/Dashboards/SideMenus/CompanyMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import reports from "assets/images/platform/reports_user_principal2.png";
import TextField from '@mui/material/TextField';


const defaultTheme = createTheme({
    palette: {...colors},
});

export default function SubscriptionAddHome() {
    const [loading, setLoading] = useState(true);
    const [serviceList, setServiceList] = useState([])
    const [serviceType, setServiceType] = useState();
    const [tenantID, setTenantID] = useState('');
    const [company, setCompany] = useState('');

    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
            fetch(API_URL + 'subs/services/list', {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL + 'subs/company-profile', {headers: authHeader() }).then(res => res.json()),

        ])
        .then( (res) => {
          setServiceList(res[1]);
          setCompany(res[2])
          setLoading(false)
        
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);
    const onServiceSelect = (e) => {
      setServiceType(e)
    }
    const setAuthURL = (e) => {
      setTenantID(e)
    }

    const handleChange = (event) => {
      setServiceType(event.target.value);
    };
  
    const ServiceForm = () => {
      if ( serviceType === "AZR") {
          return (
              <List>
                  <ListItem>
                    <ListItemText
                      primary="Azure Management Logs"
                      secondary={
                        <Fragment>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            mb={1}
                            paragraph
                          >
                            Audit logs must be enabled in your tenant. Logs will be started, if necessary, on the next check interval. They may not be available for up to 48 hours.
                          </Typography>
                          <Typography variant="caption">Go to the <Link color="darkgreen.main" href="https://compliance.microsoft.com/auditlogsearch?viewid=Test%20Tab" target="_blank">compliance center</Link> and click 'Start recording user and admin activity' to enable</Typography>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Azure Sign Ins"
                      secondary={
                        <Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            variant="body2"
                            color="text.primary"
                          >
                            Azure Active Directory premium license is required to collect Sign Ins from Azure. It does not have to be assigned to a user.
                          </Typography>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Azure Reports"
                      secondary={
                        <Fragment>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            mb={1}
                          >
                            Your MS 365 tenant defaulted to conceal user, group, and site names in 2021. In order to view user specific details by user principal name (primary email), you will need to disable that feature.
                          </Typography>
                          <Typography variant="caption" >Go to the <Link color="darkgreen.main" href="https://admin.microsoft.com/Adminportal/Home#/Settings/Services/:/Settings/L1/Reports" target="_blank">Admin Center Org Settings page</Link> and uncheck "Display concealed user, group, and site names in all reports"</Typography>
                          <img src={reports} />
                        </Fragment>
                      }
                    />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                      primary="Enter the tenant ID below and click the next button"
                      secondary={
                        <Fragment>
                          <Typography
                            variant="caption"
                            component="h5"
                            color="text.primary"
                            mb={1}
                          >
                            To get your tenant ID, click on the link below to access your Azure Portal.
                          </Typography>
                          <Typography variant="caption" sx={{ fontSize: ".8em"}}><Link color="darkgreen.main" href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Properties" target="_blank">https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Properties</Link> </Typography>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <Fragment>
                      <TextField id="standard-basic" label="Enter tenant ID" onBlur={(e) => setAuthURL(e.target.value)} variant="standard" fullWidth />
                    </Fragment>
                  </ListItem>
                  {tenantID ? 
                    <ListItem>
                      <Fragment>
                        <Button variant="outlined" href={`https://login.microsoftonline.com/${tenantID}/adminconsent?client_id=671d59c9-cd7d-4474-a1c0-798526748fc4`}>Click to Authorize</Button>
                      </Fragment>
                    </ListItem>
                  : "" }
                </List>
                 
          )
      } else if ( serviceType === "AWS") {
          return (
              <Typography variant="h6">AWS Security Intelligence is currently in private beta. Please check back soon.</Typography>
          )
      } else if ( serviceType === "DO") {
          return (
              <Typography variant="h6">DigitalOcean Security Intelligence is currently in private beta. Please check back soon.</Typography>
          )
      } else {
          return ("")
      }
  }

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
          <Box
          component="main"
          sx={{
              backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
          }}
          >
            <Container sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mb={3}>
                  <Breadcrumbs aria-label="breadcrumb" >
                          <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                          <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                      <Typography color="text.primary">Services</Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="service-select">Service</InputLabel>
                      <Select
                        labelId="service-select"
                        id="service-select"
                        value={serviceType}
                        label="Select Service"
                        onChange={handleChange}
                      >
                        {serviceList.map( row => 
                            <MenuItem key={row.service_name_short} value={row.service_name_short}>{row.service_name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>                  

                </Grid>
                {serviceType ?
                  <Grid item xs={12} md={9} lg={9}>
                    <Paper
                      sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          height: "100%",
                      }}
                      >
                          <ServiceForm />
                      </Paper>
                  </Grid>
                :""}

                <Grid item xs={12} md={3} lg={3} maxHeight={"300px"}>
                  <Paper
                  sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                  }}
                  >
                      <CompanyMenu />
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
              </Container>
          </Box>

        );
    }
}
