/* azure-callback?admin_consent=True&tenant=a239ebbc-caa7-4408-a294-3550394b5943# 
http://localhost:3000/company/subscriptions/add-success?tenant_id=a239ebbc-caa7-4408-a294-3550394b5943&tenant_name=[object%20HTMLInputElement]&service=AZR */

import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import CompanyMenu from 'components/Dashboards/SideMenus/CompanyMenu';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const defaultTheme = createTheme({
    palette: {...colors},
});

export default function SubscriptionAddSuccess() {
    const [loading, setLoading] = useState(true);
    const [subs, setSubs] = useState([]);
    const [selected, setSelected] = useState();
    const [company, setCompany] = useState()
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const admin_consent = searchParams.get("admin_consent")
    const tenant = searchParams.get("tenant")
    const service_id = "AZR"
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }

    useEffect(() => {
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
            }),
            fetch(API_URL + 'subs/subscriptions/'+service_id, {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL+'subs/company-profile', {headers: authHeader() }).then(res => res.json()),    

        ])
        .then( (res) => {
          setSubs(res[1]);
          setCompany(res[2])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, [searchParams]);

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                                <Typography color="text.primary"></Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Typography variant="h4">Success!</Typography>
                            <Typography variant="body2">You have successfully configured your new subscription.</Typography>
                            <Typography variant="body2">You should start seeing some data in the next 102 hours. Please check your item and trigger configuration. </Typography>
                            <Typography variant="body2" mt={4}>Please contact support if you do not see new items and triggers for your tenant, or if data is not being collected
                            after 2 hours.</Typography>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} maxHeight={"300px"}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                            >
                                <CompanyMenu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>

        );
    }
}
