import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useParams, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import Microsoft365Menu from 'components/Dashboards/SideMenus/Microsoft365Menu';
import AzureSignInsList from './AzureSignInsList';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const defaultTheme = createTheme({
    palette: {...colors},
});

export default function AzureUserSignIns() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [signins, setSignins] = useState([]);
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page= searchParams.get("page")
    const { user_id } = useParams();
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [fromTime, setFromTime] = useState(new Date());
    const [toTime, setToTime] = useState(new Date());
    const [buttonLoading, setButtonLoading] = useState(false)

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    const filters = [];

    searchParams.forEach((value, key) => {
        if ( key === "filter" ) {
            filters.push(value)
        }
    })

    const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        searchParams.append("page", value)
        setSearchParams(searchParams)
        setLoading(false)
    }
       
    const updateDate = (() => {
        setButtonLoading(true)
        if ( fromTime.unix() > toTime.unix()) {
            setError(true)
            setErrorMessage("From date must be less than To date")
        } else {
            setSearchParams({from: fromTime.unix(), to: toTime.unix()})
        }
        setButtonLoading(false)
    })

    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    
    useEffect(() => {
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL + 'azure/sign-ins/user/'+user_id+'?'+searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/user/'+user_id, {headers: authHeader() }).then( res => res.json())
        ])
        .then( (res) => {
            setCloudkeys(res[1]) 
            setSignins(res[2]["data"])
            setNumPages(res[1]["num_pages"])
            setHasNext(res[1]["has_next"])
            setHasPrev(res[1]["has_previous"])
            setUser(res[3])
            setLoading(false)
        })
       
    }, [searchParams]);
    document.title = "Azure User "+ user.display_name+" Message Rules"
      
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                <Link to="/azure/users" ><Typography color="text.primary">Azure Users</Typography></Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography color="darkgreen.main" component={Link} to={`/azure/users/${user.user_id}`} sx={{ textDecoration: "none" }} variant="h4">{user.display_name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Grid item xs={12} md={12} mb={2} >
                                <AzureSignInsList signins={signins} title={"Recent Sign In Failures"} filters={filters} />
                                <Grid mt={5} >
                                    {numPages > 0 ? <ShowPagination /> : "" }
                                </Grid>
                            </Grid>
                          
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container spacing={3}>
                               
                                <Grid item xs={12} >
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ mb: 2 }}>Graph date range</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Select start date" disableFuture onChange={setFromTime} />
                                            <DatePicker label="Select end date" disableFuture onChange={setToTime} sx={{ mt: 2 }} />
                                        </LocalizationProvider>
                                        {buttonLoading ? <Button sx={{ mt: 2}} variant="contained" size="small" >Loading</Button> : <Button sx={{ mt: 2}} variant="outlined" size="small" onClick={updateDate}>Update</Button> }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Box>
            </ThemeProvider>
        );
    }
}
