import {useState, useEffect} from 'react';
import { styled, createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import colors from "assets/theme/base/colors";
import { Outlet, Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Person, TableChart, Summarize, Room  } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ListItemIcon } from '@mui/material';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from '@mui/icons-material/Home';
import AuthService from 'components/Authentication/AuthenticationService';
import Copyright from 'components/Copyright/Copyright';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function Dashboard() {
  const [open, setOpen] = useState(true);
   const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElReport, setAnchorElReport] = useState(null);
  const [anchorElHome, setAnchorElHome] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openServices = Boolean(anchorEl);
  const openReports = Boolean(anchorElReport);
  const openHome = Boolean(anchorElHome);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickReports = (event) => {
    setAnchorElReport(event.currentTarget);
  }
  const handleCloseReports = () => {
    setAnchorElReport(null);
  }

  const handleClickHome = (event) => {
    setAnchorElHome(event.currentTarget);
  }
  const handleCloseHome = () => {
    setAnchorElHome(null);
  }

  const nav = useNavigate();
  const Logout = () => {
    AuthService.logout();
    nav("/sign-in")
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar color="darkgreen" position="absolute" open={open}>
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              
            >
              <Link to="/app"><img src={trawlLogo} width="180px" /></Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon color="light" />
              </IconButton>
              {/* Dropdown menu */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem component={Link} to="/azure/365" key={"ms365"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Microsoft 365</Typography>
                </MenuItem>
                <MenuItem disabled component={Link} to="/google/workspace" key={"workspace"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Google Workspace</Typography>
                </MenuItem>                
                <Divider />
                <MenuItem component={Link} to="/azure" key={"azure"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Microsoft Azure</Typography>
                </MenuItem>
                <MenuItem disabled component={Link} to="/aws" key={"aws"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Amazon AWS</Typography>
                </MenuItem>
                <MenuItem disabled component={Link} to="/google/gcp" key={"gcp"} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Google GCP</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} ml={8}> {/* Main NavBar Menu */}
              <Button
                  id="home-button"
                  component={Link}
                  color="light"
                  key="home"
                  aria-controls={openHome ? 'home-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openHome ? 'true' : undefined}
                  disableElevation
                  onClick={handleClickHome}
                  endIcon={<KeyboardArrowDownIcon />}
                  startIcon={<Home />}
                >
                  Home
              </Button>
              <StyledMenu
                  id="home-menu"
                  MenuListProps={{
                    'aria-labelledby': 'home-menu',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  anchorEl={anchorElHome}
                  open={openHome}
                  onClose={handleCloseHome}
                >

                  <MenuItem component={Link} to="/app" onClick={handleCloseHome} disableRipple>
                      Dashboard
                  </MenuItem>
                  <MenuItem  component={Link} to="/company/services"  onClick={handleCloseHome} disableRipple>
                      Services
                  </MenuItem>
                  <MenuItem  component={Link} to="/company/subscriptions" onClick={handleCloseHome} disableRipple>
                      Subscriptions
                  </MenuItem>
                  <MenuItem  component={Link} to="/company/users"  onClick={handleCloseHome} disableRipple>
                      Users
                  </MenuItem>
                  <MenuItem   component={Link} to="/company/alerts" onClick={handleCloseHome} disableRipple>
                      Alerts
                  </MenuItem>
                  <MenuItem   component={Link} to="/company/items" onClick={handleCloseHome} disableRipple>
                      Items
                  </MenuItem>
                  <MenuItem   component={Link} to="/company/triggers" onClick={handleCloseHome} disableRipple>
                      Triggers
                  </MenuItem>
                </StyledMenu>

              <Typography marginRight={3} />
              <Button
                  id="services-button"
                  aria-controls={openServices ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openServices ? 'true' : undefined}
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  color="light"
                  key="services"
                  startIcon={<TableChart />}
                >
                  Dashboards
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={openServices}
                  onClose={handleClose}
                >

                  <MenuItem component={Link} to="/azure/365" onClick={handleClose} disableRipple>
                      Microsoft 365
                  </MenuItem>
                  <MenuItem disabled component={Link} to="/google/workspace"  onClick={handleClose} disableRipple>
                      Google Workspace
                  </MenuItem>
                  <Divider />
                  <MenuItem  component={Link} to="/azure" onClick={handleClose} disableRipple>
                      Azure Cloud
                  </MenuItem>
                  <MenuItem disabled component={Link} to="/aws"  onClick={handleClose} disableRipple>
                      Amazon AWS
                  </MenuItem>
                  <MenuItem  disabled component={Link} to="/google/gcp" onClick={handleClose} disableRipple>
                      Google GCP
                  </MenuItem>
                </StyledMenu>
                
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar><Person /></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="user" onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <Typography component={Link} to="/company/user-profile/" color="grey.900" sx={{ textDecoration: "none"}}  textAlign="center">User Profile</Typography>
                </MenuItem>
                <MenuItem key="company" onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <RoomPreferencesIcon />
                  </ListItemIcon>
                  <Typography component={Link} to="/company/profile/" color="grey.900" sx={{ textDecoration: "none"}} textAlign="center">Company Profile</Typography>
                </MenuItem>
                <Divider />
                <MenuItem key="sign-out" onClick={handleCloseUserMenu}>
                  <ListItemIcon onClick={Logout} >
                    <LogoutIcon />
                  </ListItemIcon>
                  <Typography onClick={Logout} textAlign="center">Sign Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

          <Toolbar />


      </Container>
      <Outlet />

    </ThemeProvider>
  );
}
