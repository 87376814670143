import { Typography } from '@mui/material';
import React, { PureComponent, Fragment } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { labelStyle } from './chartlabels';
import Box from '@mui/material/Box';


export default function SharePointFileActivity({data}) {
    /* Get the number of unique, licensed users who interacted with files stored on SharePoint sites.*/
    function bytes(bytes, label) {
        if (bytes === 0) return '';
        if (label === "None") return bytes;
        var s = ['bytes', 'K', 'M', 'G', 'T', 'P'];
        var e = Math.floor(Math.log(bytes)/Math.log(1024));
        var value = ((bytes/Math.pow(1024, Math.floor(e))).toFixed(2));
        e = (e<0) ? (-e) : e;
        if (label) value += ' ' + s[e];
        return value;
      }
    return (
        <>
            <Typography variant="body1" >SharePoint File Activity</Typography>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="report_date" angle={20} tickMargin={30} height={40} style={labelStyle}/>
                <YAxis style={labelStyle}/>
                <Tooltip />
                <Legend verticalAlign="top" height={36}/>
                <Line type="monotone" dataKey="viewed_edited" stroke="#7C69EF" activeDot={{ r: 4 }} />
                <Line type="monotone" dataKey="synced" stroke="#DB2500" activeDot={{ r: 4 }} />
                <Line type="monotone" dataKey="shared_internal" stroke="#DB9A00" activeDot={{ r: 4 }} />
                <Line type="monotone" dataKey="shared_external" stroke="#002711" activeDot={{ r: 4 }} />
                </LineChart>
            </ResponsiveContainer>
            <Box mt={2}><Typography variant="caption">The number of unique, licensed users who interacted with files stored on SharePointSites</Typography></Box>
        </>
    );
}
