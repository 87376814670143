import { Typography } from '@mui/material';
import React, { PureComponent, Fragment } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { labelStyle } from './chartlabels';
import Box from '@mui/material/Box';


export default function MailboxUsage({data}) {
    
    return (
        <>
            <Typography variant="body1" >Mailbox Usage (Total/Active)</Typography>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="report_date" angle={20} tickMargin={30} height={40} style={labelStyle}/>
                <YAxis style={labelStyle}/>
                <Tooltip />
                <Legend verticalAlign="top" height={36}/>
                <Line type="monotone" dataKey="total" stroke="#218d83" activeDot={{ r: 4 }} />
                <Line type="monotone" dataKey="active" stroke="#0B4EDB" activeDot={{ r: 4 }}/>
                </LineChart>
            </ResponsiveContainer>
            <Box mt={2}><Typography variant="caption">The total number of user mailboxes and how many are active each day</Typography></Box>
        </>
    );
}
