import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Flag, EmojiFlags, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AzureLogComments from './AzureLogComments';
import CommentIcon from '@mui/icons-material/Comment';



function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

function formatClock(date) {
    const clock = new Date(date)
    return clock.toLocaleString()
}

function printJSON(data) {
    return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
}

import { forwardRef } from "react";  

const setFlag = (logId) => {
    var el = document.getElementById("flag-"+logId)
    fetch(API_URL+'azure/management/set_flag?log_id='+logId, {headers:authHeader() }).then( res => res.json() )
    .then((res) => {
        if ( res["log_flagged"] === true ) {
                el.style.color = "red"
                return false
            } else {
                el.style.color =  "lightgrey"
                return false
            }
        })
    .catch( err => {
        console.log(err)
    })
};

function Row(props) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])


    const fetchComments = () =>  {
        fetch(API_URL+'azure/management/audit/comments?log_id='+row.log_id, {headers: authHeader() }).then( res => res.json() )
        .then( res => {
            setComments(res)
            setOpen(true);
    
        })
        .catch(err => {
            console.log(err)
        })
        console.log(comments)
    }
    const TypoLink = forwardRef (
        (
            {children, ...rest },
            ref
        ) => (
            <Typography 
                {...rest}
                ref={ref}
                onClick={fetchComments}  
                variant="caption"
                sx={{ cursor: "pointer", textDecoration: "underline"}}
                
            >
                {children}
            </Typography>
        )
    
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: "90%",
        width: "75%"
    };
    function printJSON(data) {
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }
    return (
      <Fragment>
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
        <Fade in={open}>
            <Box sx={style}>
            <Table size="small" aria-label="Exchange Logs">
                    {       
                        Object.entries(row.log_data).map(([key, value]) => {
                            if ( typeof(value) === 'object' ) {
                                value = JSON.stringify(value)
                            } 
                           return (
                            <TableRow >
                                <TableCell colSpan={2}><Typography variant="caption">{key}</Typography></TableCell>
                                <TableCell><Typography variant="caption">{value}</Typography></TableCell>
                            </TableRow>)
                        }) 
                    }
                </Table>
                <AzureLogComments comments={comments} log_id={row.log_id} />
            </Box>
        </Fade>
        </Modal>
      </Fragment>
    );
  }
  
export default function ExchangeLogList({logs, type}) {
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();

    
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleString()
      }
    const rows = []
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="logs-table">
                    <TableHead>
                        <TableRow><TableCell colSpan={6}>
                            <Typography variant="h6">Exchange Logs</Typography></TableCell></TableRow>
                        <TableRow>
                            <TableCell>Created</TableCell>
                            <TableCell align="left">Workload</TableCell>
                            <TableCell align="left">Operation</TableCell>
                            {type === "user" ? "" : <TableCell align="left">User ID</TableCell>}
                            <TableCell align="left"><Flag sx={{ fontSize: "medium" }} /></TableCell>
                            <TableCell><CommentIcon sx={{ fontSize: "medium" }}  /> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {logs.map((row) => (
                        <Row key={row.id} row={row} type={type} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>


        );
}
