import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';



function AzureLogCommentForm({ log_id, parent_id }) {
    const [ submitting, setSubmitting ] = useState(false);
    const submitComment = (e) => {
        setSubmitting(true)
        e.preventDefault()
        let opts = {
            'log_id': log_id ,
            'parent_id': parent_id,
            'comment': e.target.comment.value
        }
        Promise.all([
          fetch(API_URL + "azure/management/audit/comments", {method: "POST", headers: authHeader(), body: JSON.stringify(opts)})
          .then( (res) => {
            setSubmitting(false)
            document.getElementById("main_comments").reset();
            document.getElementById(e.target.id).reset();
          })
          .catch( (err) => {
            console.log(err)
            setSubmitting(false)
          })
        ])
    }
    return ( 
        <Box id="main_comments" style={{ width: "50%" }} component="form" role="form" onSubmit={(e) => submitComment(e)}>
            <Typography component="label" variant="caption" fontWeight="bold">
                Comment
            </Typography>
            <input type="hidden" value={log_id} />
            <Input multiline rows={4} reuqired type="text" name="comment" dark fullWidth="true" />
            <Box mt={4} mb={1}>
                <Button type="submit" variant="contained" color="darkgreen" size="small" ><Typography variant="caption" color="grey.200">Save Comment</Typography></Button>
            </Box>
        </Box>
        )
  }  
  

export default function AzureLogComments({comments, log_id}) {
    const [reply, setReply] = useState(null)
    return(
        <Container>
            <Box p={4} style={{ position: 'relative', width: "75%"}}>
                <AzureLogCommentForm log_id={log_id} />
            </Box>
            <Box p={5} style={{ position: 'relative', width: "75%"}}>
                {comments.length > 0 ? 
                <div style={{ fontFamily: 'sans-serif' }}>
                    {comments.map((comment) => (
                        <Box id={comment.comment_id} sx={{ mb: 2, borderLeft: '1px solid grey' }} ml={comment.depth*2}>
                        <CardContent >
                            <Typography variant="caption"  color="text.secondary" gutterBottom>{comment.account.email}</Typography>
                            <Typography variant="body2" component="div">{comment.comment}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button id={comment.comment_id} color="primary" size="small" onClick={(e) => setReply(e.target.id)}>Reply</Button>
                        </CardActions>
                        {reply === comment.comment_id ? <Box sx={{ mb: 1, mt:1, borderLeft: '1px solid primary' }} ml={ comment.depth*2+1 }><AzureLogCommentForm log_id={log_id} parent_id={comment.comment_id} /> </Box>: ""}
                        </Box>
                        
                    ))}
                </div>
                : <Typography variant="body2">No comments</Typography> }
            </Box>
        </Container>
    )
}