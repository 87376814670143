import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Chart from 'components/Dashboards/Chart';
import Deposits from 'components/Dashboards/Deposits';
import Orders from 'components/Dashboards/Orders';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Breadcrumbs, Card, CardHeader, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import SubscriptionsList from './SubscriptionsList';
import SummaryList from './SummaryList';
import CompanyMenu from '../SideMenus/CompanyMenu';
import UserList from './UserList';


const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });
function formatClock(date) {
        const clock = new Date(date)
        return clock.toISOString().split('T')[0]
      }
export default function CopmpanyProfile() {
    const [company, setCompany] = useState([])
    const [loading, setLoading] = useState(true);
    const [subServices, setSubServices] = useState([]);
    const [users, setUsers] = useState([]);
    const nav = useNavigate();

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
      function formatClock(date) {
        const clock = new Date(date)
        return clock.toISOString().split('T')[0]
      }
    
      useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'subs/company-profile', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'dashboard/services', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'subs/users', {headers: authHeader() }).then(res => res.json()),
        ])
        .then( (res) => {
          setCompany(res[1]);
          setSubServices(res[2])
          setUsers(res[3])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);

      console.log(company)
      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
          <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                                <Breadcrumbs aria-label="breadcrumb" >
                                    <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                    <Typography color="text.primary">Company</Typography>
                                </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Card backgroundColor="primary">
                                  <CardContent>
                                      <Typography variant="h5">{company.name}</Typography>
                                      <Typography variant="caption">Since {formatClock(company.joined)}</Typography>
                                  </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12}>
                              <SubscriptionsList subscriptions={subServices.services} type="short" count={subServices.services.length} />
                            </Grid>
                            <Grid item xs={12}>
                              <UserList users={users} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: "280px",
                            }}
                            >
                                <CompanyMenu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>
              </ThemeProvider>
        );
    }
}
