import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from 'components/Copyright/Copyright';
import colors from "assets/theme/base/colors";
import { Link , useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import { CircularProgress } from '@mui/material';

const defaultTheme = createTheme({
  palette: {...colors}
});


export default function UserAdd() {
  const wait = async (d) => new Promise(r => setTimeout(d, r));
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert ] = useState(false);
  const nav = useNavigate();
  const handleSubmit = (event) => {
    setAlert(false)
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if ( data.get('password') !== data.get('password-confirm')) {
      setAlert(true)
      setAlertMessage("Passwords do not match. Please try again.")
    } else {
        let opts = { 
            method: 'POST', 
            headers: authHeader(),
            body: JSON.stringify({
                'first_name': data.get('firstName'), 
                'last_name': data.get('lastName'), 
                'email': data.get('email'), 
                'password': data.get('password')
            })
        };
        Promise.all([ 
            fetch(API_URL + 'subs/add-user', opts)
            .then( (res) => {
                if ( res.status === 201 ) {
                    setAlertMessage("Successfully created user")
                    setSuccessAlert(true)
                    wait(2000);
                    nav('/company/profile')
                    
                } else if ( res.status === 401 ) {
                    setAlertMessage("You must be a primary account holder.")
                    setAlert(true)
                } else if ( res.status === 409 ) {
                    setAlertMessage("This email is already registered to a user.")
                    setAlert(true)
                } else if ( res.status === 500 ) {
                    setAlertMessage("We've encountered an error. Please try again later.")
                } else if ( res.response.status === 424 ) {
                    setAlertMessage("Company information saved, but user registration failed. Please contact us to report the error.")
                } else {
                    setAlert(true)
                    setAlertMessage("An unknown error has occurred. Please contact support at support@trawlai.com")
                }
                setLoading(false)
            })
            .catch( err => {
                setAlert(true)
                setAlertMessage("An unknown error has occurred. Please contact support at support@trawlai.com")
                setLoading(false)
            }),
            

        ]);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" mt={2}>
            Add User
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            { alert ? 
                <Grid item xs={12}><Alert severity='error'  action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}>{alertMessage}</Alert></Grid>
                : ""}
                { successAlert ? 
                <Grid item xs={12}><Alert severity='success'  
                sx={{ mb: 2 }}>{alertMessage}</Alert></Grid>
                : ""}
              <Grid item xs={12} sm={6}>
               
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Company Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password-confirm"
                  label="Confirm Password"
                  type="password"
                  id="password-confirm"
                  autoComplete="new-password"
                />
              </Grid>
              
            </Grid>
            {loading ?
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="darkgreen"
                disabled
              >
                <Typography color="light.main"><CircularProgress color="dark" size="1rem" /></Typography>
              </Button>
            :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="darkgreen"
              >
                <Typography color="light.main">Create User</Typography>
              </Button>
            }
            
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}