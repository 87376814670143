import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import colors from 'assets/theme/base/colors';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import AzureCloudSelect from '../AzureCloudSelect';
import Button from '@mui/material/Button';


var newDate = new Date()

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none' 
    },
  },
 });


export default function MS365EmailCharts() {
    const [loading, setLoading] = useState(false)
    const [cloudKeys, setCloudkeys] = useState([]);
    const [cloudKey, setCloudKey] = useState(localStorage.getItem("cloud_key_azure"));
    const [hasAzureServices, setHasAzureServices] = useState(false);


    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid container sx={{ mb: 10 }}>
                        {/* Top row, breadcrumbs and tenant select */}
                        <Grid container spacing={3} sx={{ mt: 4, mb: 4 }}>
                            <Grid item xs={12} md={9} mb={3}>
                                <Breadcrumbs aria-label="breadcrumb" >
                                        <Link to="/app" ><Typography color="darkgreen.main">Home</Typography></Link>
                                        <Link to="/azure"><Typography color="darkgreen.main">Azure</Typography></Link>
                                        <Link to="/azure/365"><Typography color="darkgreen.main">MS 365 Home</Typography></Link>
                                        <Typography color="darkgreen.main">Email Reports</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                            </Grid>
                        </Grid>
                        {/* end of top row */}
                        <Grid container spacing={3} >
                            {/* Main body for content */}
                            <Grid item xs={12} md={9} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3} mt={2} style={{ display: 'flex' }}>
                                        <Card style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                                            <CardContent>
                                                <Typography color="darkgreen.main" sx={{ mb: 1.5 }} gutterBottom>
                                                    Mailbox Usage by User
                                                </Typography>
                                                <Typography variant="body2">
                                                    Items, deleted items, deleted size, total storage used, created date, and last active date by user 
                                                    sorted by storage used.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Link to="/azure/365/e-mail/reports/mailbox-usage-by-user" ><Button color="darkgreen" size="small">Run Report</Button></Link>
                                            </CardActions>
                                        </Card>
                                        
                                    </Grid>
                                    <Grid item xs={12} md={3} mt={2}  style={{ display: 'flex' }}>
                                        <Card style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                                            <CardContent>
                                                <Typography color="darkgreen.main" sx={{ mb: 1.5 }} gutterBottom>
                                                    Email Activity by User
                                                </Typography>
                                                <Typography variant="body2">
                                                    Received, Read, Sent count sorted by email address.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Link to="/azure/365/e-mail/reports/email-activity-by-user" ><Button color="darkgreen" size="small">Run Report</Button></Link>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        )
    }
}