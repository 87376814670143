import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, Button, CircularProgress,  } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from '../SideMenus/CompanyMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';


const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

export default function SubscriptionDeleteConfirmation() {
    const [loading, setLoading] = useState(true);
    const [deleteCall, setDeleteCall] = useState(false)
    const [deleteCallSuccess, setDeleteCallSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [subService, setSubService] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [confirmed, setConfirmed] = useState(false);
    const { subscription_id } = useParams();
    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'subs/services/'+subscription_id, {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'subs/user-info', {headers: authHeader() }).then(res => res.json()),
        ])
        .then( (res) => {
          setSubService(res[1]);
          setUserInfo(res[2]);
          setLoading(false)
          if ( subServices.services.length === 0 ) {
            nav("/company/services/add")
          }
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);

      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }
      
    const handleSubmit = (event) => {
        setDeleteCall(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        Promise.all([
            fetch(API_URL + "subs/subscriptions/delete/", {method: 'POST', headers: authHeader(), body: JSON.stringify({'subscription_id': subService.id  }) } )
            .then( (res)  => {
                if (res.ok) {
                    // nav("/company/subscriptions/add-success?tenant_id="+tenant+"&tenant_name="+tenant_name+"&service="+service_id)
                    setDeleteCallSuccess(true)
                    setDeleteCall(false)
                } else if ( res.status === 401) {
                    console.log("Unauthorized")
                    setErrorMessage("You are not authorized to delete this tenant.")
                    setError(true)
                } else {
                    // setError(true)
                    // setErrorMessage("An unknown error has occurred")
                    console.log(res)
                }
                
            })
            .catch( err => {
                console.log("Error captured: "+err)
            })
        ])
        
        
    };
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
            <ThemeProvider theme={defaultTheme}>
            <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
            }}
            >
            <Container sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mb={3}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                        <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                        <Link to="/company/subscriptions" ><Typography color="text.primary">Subscriptions</Typography></Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                        { deleteCallSuccess ? 
                            <Card>
                                <CardHeader title={`Subscription ${subService.sub_cloud.name} deleted`} />
                                <CardContent>
                                <Box component="form" onSubmit={handleSubmit} required >
                                    <Button component={Link} to="/company/subscriptions/add" variant="contained" type="submit">Add new subscription</Button>
                                </Box>
                                </CardContent>
                                
                            </Card>
                        :
                            <Card>
                                <CardHeader title={`Confirm delete of subscription ${subService.sub_cloud.name}`} />
                                
                                <CardContent>
                                <Box component="form" onSubmit={handleSubmit} required >
                                    { deleteCall ? <Button variant="outlined" disabled>Confirm Delete</Button> : <Button variant="contained" type="submit">Confirm Delete</Button>}
                                </Box>
                                </CardContent>
                            </Card>
                        }

                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: "100%",
                    }}
                    >
                        <CompanyMenu />
                    </Paper>
                </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </ThemeProvider>
        );
    }
}
