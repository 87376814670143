/* azure-callback?admin_consent=True&tenant=a239ebbc-caa7-4408-a294-3550394b5943# */

import { useState, useEffect, Fragment } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import CompanyMenu from 'components/Dashboards/SideMenus/CompanyMenu';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


const defaultTheme = createTheme({
    palette: {...colors},
});

export default function SubscriptionAddAzureCallBack() {
    const [loading, setLoading] = useState(true);
    const [subs, setSubs] = useState([]);
    const [selected, setSelected] = useState();
    const [company, setCompany] = useState()
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const admin_consent = searchParams.get("admin_consent")
    const tenant = searchParams.get("tenant")
    const service_id = "AZR"
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }

    useEffect(() => {
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
            }),
            fetch(API_URL + 'subs/subscriptions/'+service_id, {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL+'subs/company-profile', {headers: authHeader() }).then(res => res.json()),    

        ])
        .then( (res) => {
          setSubs(res[1]);
          setCompany(res[2])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, [searchParams]);

    const handleChange = (event) => {
        setSelected(event.target.value);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        Promise.all([
            fetch(API_URL + "subs/subscriptions/subscribe/", {method: 'POST', headers: authHeader(), body: JSON.stringify({'tenant_name': data.get("tenant_name"), 'tenant_id': tenant, 'subscription': selected, 'service_id': 'AZR' })} )
            .then( (res)  => {
                if (res.ok) {
                    nav("/company/subscriptions/add-success?tenant_id="+tenant+"&tenant_name="+tenant_name+"&service="+service_id)
                } else if ( res.status === 405) {
                    setError(true)
                    setErrorMessage("Service type not accepted")
                } else if ( res.status === 406 ) {
                    setError(true)
                    setErrorMessage("Invalide service or subscription")
                } else if ( res.status === 409 ) {
                    setError(true)
                    setErrorMessage("Tenant name exists")
                } else {
                    setError(true)
                    setErrorMessage("An unknown error has occurred")
                }
                
            })
            .catch( err => {
                console.log("Error captured: "+err)
            })
        ])
        
    };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };
  
    const action = (
        <Fragment>
            <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            >
            <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                                <Typography color="text.primary"></Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            {admin_consent === "True" ? 
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: "100%",
                                        }}
                                    >
                                    <Box component="form" onSubmit={handleSubmit} required >
                                        <TextField 
                                             inputProps={{ maxLength: 16 }}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="tenant_name"
                                            label="Tenant Name"
                                            name="tenant_name"
                                            autoFocus
                                        />
                                        <FormControl mt={4} fullWidth>
                                            <InputLabel id="service-select">Service</InputLabel>
                                            <Select
                                                labelId="service-select"
                                                id="service-select"
                                                value={selected}
                                                label="Select Service"
                                                onChange={handleChange}
                                                required
                                            >
                                                {subs.map( row => 
                                                    <MenuItem key={row.id} value={row.id}>{row.level}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        { loading ?
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                disabled
                                            >
                                                Submitting
                                            </Button>
                                            :
                                            <Button
                                                color="darkgreen"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}

                                            >
                                                <Typography color="light.main">Add Subscription</Typography>
                                            </Button>
                                            }
                                    </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                <TableContainer component={Paper} sx={{ minHeight: "100%"}}>
                                    <Table sx={{ minWidth: 100 }} aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow><TableCell colSpan={5}><Typography variant="h6">Subscription Options</Typography></TableCell></TableRow>
                                            <TableRow>
                                                <TableCell><Typography variant="caption">Level</Typography></TableCell>
                                                <TableCell align="left"><Typography variant="caption">Description</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="caption">$/User</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {subs.map((row) => (
                                            <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row"><Typography variant="caption">{row.level}</Typography></TableCell>
                                                <TableCell align="left"><Typography variant="caption">{row.description}</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="caption">${row.per_user_cost}/mo</Typography></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                     
                                    </Table>
                                </TableContainer>
                                </Grid>
                            </Grid>
                            :   
                                <Typography color="error">Authorization Failed! Please try again.</Typography>
                            }
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} maxHeight={"300px"}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                            >
                                <CompanyMenu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Snackbar open={error} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>

        );
    }
}
