import { Routes, Route } from "react-router-dom"
import PrivateRoute from "components/Authentication/PrivateRoute";
import AzureHome from "./AzureLayout";
import Microsoft365Home from "./Microsoft365/Microsoft365Home";
import AzureUsers from "./Cloud/AzureUsers";
import AzureUser from "./Cloud/AzureUser";
import AzureSignIns from "./Cloud/AzureSignIns";
import AzureUserSignIns from "./Cloud/AzureUserSignIns";
import AzureManagementLogs from "./Cloud/AzureManagementLogs";
import NotFound from "components/NotFound";
import { Navigate } from "react-router-dom";
import AzureUserManagementLogs from "./Cloud/AzureUserManagementLogs";
import MessageRules from "./Microsoft365/MessageRules";
import AzureSecureScoreView from "./Microsoft365/SecureScoreView";
import MailboxFolders from "./Microsoft365/MailboxFolders";
import MS365EmailCharts from "./Microsoft365/MS365EmailCharts";
import MS365EmailReports from "./Microsoft365/MS365EmailReports";
import MailboxUsageByUser from "./Microsoft365/Reports/MailboxUsageByUser";
import EmailActivityByUser from "./Microsoft365/Reports/EmailActivityByUser";

export function AzureRoutes() {
    return (
            <Routes> 
                <Route path="/365" element={<Microsoft365Home />} />
                <Route path="/365/message-rules" element={<MessageRules />} />
                <Route path="/365/e-mail/message-rules" element={<MessageRules />} />
                <Route path="/365/e-mail/charts" element={<MS365EmailCharts />} />
                <Route path="/365/e-mail/reports" element={<MS365EmailReports />} />
                <Route path="/365/e-mail/reports/mailbox-usage-by-user" element={<MailboxUsageByUser />} />
                <Route path="/365/e-mail/reports/email-activity-by-user" element={<EmailActivityByUser />} />
                <Route path="/management-logs" element={<AzureManagementLogs />} />
                <Route path="/users" element={<AzureUsers />} />
                <Route path="/users/:user_id" element={<AzureUser />} />
                <Route path="/users/:user_id/signins" element={<AzureUserSignIns />} />
                <Route path="/users/:user_id/logs" element={<AzureUserManagementLogs />} />
                <Route path="/users/:user_id/mailbox-folders" element={<MailboxFolders />} />
                <Route path="/sign-ins" element={<AzureSignIns />} />
                <Route path="/secure-score" element={<AzureSecureScoreView />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>

    )
}
