import { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { IconButton, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import { Group, Description, NotificationsActive, Directions, Flaky, Login } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AzureCloudSelect from './AzureCloudSelect';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BackupIcon from '@mui/icons-material/Backup';
import LanguageIcon from '@mui/icons-material/Language';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import colors from 'assets/theme/base/colors';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';


const defaultTheme = createTheme({
  palette: {...colors},
});
const hoverColor="warning.main"

export default function Microsoft365Menu() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  };
  const DrawerList = (
    <List dense>
        <Divider />
        <ListItem>
                <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium' textTransform='uppercase'>Azure</Typography>} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemButton component={Link} to="/azure/users" dense>
            <ListItemIcon sx={{ color: "inherit"  }}>
              <Directions  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Users</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/sign-ins" dense>
            <ListItemIcon sx={{ color: "inherit"  }}>
              <Login  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Sign Ins</Typography>} />
          </ListItemButton>
        </ListItem>
      
        <ListItem>
          <ListItemButton role={undefined} component={Link} to="/azure/management-logs" dense>
            <ListItemIcon sx={{ color: "inherit"  }}>
              <Description fontSize="small" />
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Management Logs</Typography>} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem>
            <ListItemButton component={Link} to="/azure/365" dense>
                <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium' textTransform='uppercase'>Microsoft 365</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem > 
                <ListItemText sx={{ml: 1}} primary={<Typography variant="caption" fontWeight='fontWeightMedium' textTransform='uppercase'>E-Mail</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2 }}>
              <Directions  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Message Rules</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/e-mail/charts" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <InsertChartIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Charts</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/e-mail/reports" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <SummarizeIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Reports</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
                <ListItemText  sx={{ml: 1}}  primary={<Typography variant="caption" fontWeight='fontWeightMedium' textTransform='uppercase'>SharePoint</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <LanguageIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Sites</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <BackupIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>OneDrive</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <InsertChartIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Charts</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <SummarizeIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Reports</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
                <ListItemText  sx={{ml: 1}}  primary={<Typography variant="caption" fontWeight='fontWeightMedium' textTransform='uppercase'>Teams</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <ContactPhoneIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Voice</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <InsertChartIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Charts</Typography>} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component={Link} to="/azure/365/message-rules" dense>
            <ListItemIcon sx={{ color: "inherit", pl: 2   }}>
              <SummarizeIcon  fontSize="small"/>
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="caption" fontWeight='fontWeightMedium'>Reports</Typography>} />
          </ListItemButton>
        </ListItem>
    </List>
  );

  return (
    <>
      <Box sx={{ width: '100%', minHeight: "100%", pr:5, flexGrow: 1, display: {xs: 'flex', md: 'none'}}} >
        <Button onClick={toggleDrawer(true)}><Avatar ><MenuIcon /></Avatar></Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </Box>
      <Box sx={{ width: '100%', minHeight: "100%", pr:5, flexGrow: 1, display: {xs: 'none', md: 'flex'}}} >
          {DrawerList}
      
      </Box>
    </>
  );
}