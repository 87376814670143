import React from "react";
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Routes, Redirect, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

/* import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl"; */
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";


// import routes from "routes";

// import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";

// import Dashboard from "layouts/dashboard";
import Dashboard from "components/Dashboards/Dashboard";
import SignIn from "components/SignIn/SignIn";
import SignUp from "components/SignUp/SignUp";
import { AzureRoutes } from "components/Dashboards/Azure/AzureRoutes";
import { CompanyRoutes } from "components/Dashboards/Main/CompanyRoutes";
import PrivateRoute from "components/Authentication/PrivateRoute";
import NotFound from "components/NotFound";
import Home from "components/Dashboards/Main/Home";
import Microsoft365Home from "components/Dashboards/Azure/Microsoft365/Microsoft365Home";
import TermsOfService from "components/TermsOfService/TermsOfService";
import { ReportRoutes } from "components/Reports/ReportRoutes";
import ReactGA from 'react-ga4';
import AzureLayout from "components/Dashboards/Azure/AzureLayout";
import AzureHome from "components/Dashboards/Azure/AzureHome";
const TRACKING_ID = 'G-GZH3CWD624'
ReactGA.initialize(TRACKING_ID)

// import SignIn from "layouts/authentication/sign-in"

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  /*
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

*/

  return  (
    /* 
    <ThemeProvider theme={theme}>
      <PrivateRoute path="/dashboard"/>
      <PrivateRoute path="/company" />
      <PrivateRoute path="/user" />
      <PrivateRoute path="/azure" />      
      <PrivateRoute path="/google" />
      <PrivateRoute path="/amazon" />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Trawl AI"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
      </Routes>
    </ThemeProvider>
    */
    
    <Routes>
      <Route path="/" element={<Navigate to="/app" />} />
      <Route element={<PrivateRoute><Dashboard /></PrivateRoute>}>
        <Route path="/app" element={ <Home />} />
        <Route element={<AzureLayout />}>
          <Route path="/azure" element={<AzureHome />} />
          <Route path="/azure/*" element={<AzureRoutes />} />
        </Route>
        <Route path="/company/*" element={ <PrivateRoute><CompanyRoutes /></PrivateRoute> } />
        <Route path="/reports/*" element={ <PrivateRoute><ReportRoutes /></PrivateRoute> } />
      </Route>
      <Route path="/sign-in" element={ <SignIn /> } />
      <Route path="/sign-up" element={ <SignUp /> } />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  
}



/*
App.js
 // Extra routes can be added here with content : <Route path="/books" element={<h1>Extra Content </h1>} />
<Routes>
  <Route path="/" element={ <Home /> } />
  <Route path="/books/*" element={<BookRoutes />}> // Render parent layout to each page: <Outlet /> required in layout page, child component rendering
    
  </Route>
</Routes>

BookRoutes.js
export function BookRoutes() {
  return 
   <>
     <Routes>
       <Route element={<BookLayout /> }>
        <Route index element={<BookList />} /> // Uses /books as an index component
        <Route path=":id" element={ <Book />} />
        <Route path="new" element={ <NewBook />} />
        <Route index element={<BookList />} /> (/books route)
       </Route>
     </Routes>
    </>
}
BookLayout.js
  export funciton BookLayout() {
    return (
      <>
      <Link to="/books/1">Book 1</Link>
      <Link to="/books/2">Book 2</Link>
      <NavLink 
        style={({ isActive }) => { 
          return isActive ? {color: "red"} : {}
        }}
        to="/"
      >
       {({ isActive }) => {
         return isActive ? "Active Home" : "Home"
       }}
       </NavLink>
         // Or, use css to style active links : in style.css .active { color: red; }, then import style.css
      <Outlet context={{ hello: "World" }} /> // Renders component in child here context rendered with const obj = useOutletContext()
      </>
   )
  }


<Route path="/books/*" element={<BookRoutes />} />
*/