import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block, DeleteForever, MarkChatRead } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Checkbox from '@mui/material/Checkbox';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function MessageRulesList({folders}) {
    console.log(folders)
    /* 
        rules.forEach((val) => {
            console.log(val.actions)
            console.log(val.conditions)
            console.log(val.exceptions)
        })
        for (const [key, value] of Object.entries(object1)) {
            console.log(`${key}: ${value}`);
        }
    */
    function printJSON(data) {
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small" id="item-table">
                <TableHead>
                    
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell align="left">Display Name</TableCell>
                        <TableCell align="left">Parent Folder</TableCell>
                        <TableCell align="left">Child Folders (#)</TableCell>
                        <TableCell align="left">Unread Items (#)</TableCell>
                        <TableCell align="left">Total Items (#)</TableCell>
                        <TableCell align="left">Size (bytes)</TableCell>
                        <TableCell align="left">Hidden?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rules.map((row) => (
                        <>
                            <TableRow
                                key={row.id}
                                >
                                <TableCell component="th" scope="row"><Typography variant="caption">{row.display_name}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.parent_folder_id}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.child_folder_count}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.unread_item_count}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.total_item_count}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.size_in_bytes}</Typography></TableCell>
                                <TableCell align="left">
                                    <Typography variant='caption' color="grey.600" >
                                        { row.is_hidden 
                                        ? <Checkbox size="small" id={`check-${row.id}`} checked />
                                        : <Checkbox size="small" id={`check-${row.id}`}  />
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                          
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>


    );
}
