import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { IconButton, Typography } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import { Group, MiscellaneousServices, NotificationsActive, ContactMail, Flaky, Subscriptions } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const hoverColor="warning.main"

export default function CompanyMenu() {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }} >
      <nav aria-label="main mailbox folders">
        <List>
            {/*
            <ListSubheader component="div" id="nested-list-subheader">
                Configuration Summary
            </ListSubheader>
            <Divider />
            */}
            <ListItem
                sx={{
                    ":hover": {
                    backgroundColor: hoverColor,
                    color: "light.main"
                    }
                }}
                disablePadding
            >
                <ListItemButton component={Link} to="/company/services"
                    dense>
                    <ListItemIcon sx={{ color: "inherit"  }}>
                        <MiscellaneousServices color="inherit" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText  primary="Services" />
                </ListItemButton>
            </ListItem>
            
            <ListItem
                sx={{
                    ":hover": {
                    backgroundColor: hoverColor,
                    color: "light.main"
                    }
                }}
                disablePadding
            >
                <ListItemButton component={Link} to="/company/subscriptions"
                    dense>
                    <ListItemIcon sx={{ color: "inherit"  }}>
                        <Subscriptions color="inherit" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText  primary="Subscriptions" />
                </ListItemButton>
            </ListItem>

            <ListItem
                sx={{
                    ":hover": {
                    backgroundColor: hoverColor,
                    color: "light.main"
                    }
                }}
                disablePadding
            >
              <ListItemButton component={Link} to="/company/users" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <Group  fontSize="small"/>
                </ListItemIcon>
                <ListItemText  primary="Users" />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                  ":hover": {
                  backgroundColor: hoverColor,
                  color: "light.main"
                  }
              }}
              disablePadding
            >
              <ListItemButton component={Link} to="/company/alerts" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <NotificationsActive  fontSize="small"/>
                </ListItemIcon>
                <ListItemText  primary="Alerts" />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                  ":hover": {
                  backgroundColor: hoverColor,
                  color: "light.main"
                  }
              }}
              disablePadding
            >
              <ListItemButton role={undefined} component={Link} to="/company/items" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <Flaky fontSize="small" />
                </ListItemIcon>
                <ListItemText  primary="Items" />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                  ":hover": {
                  backgroundColor: hoverColor,
                  color: "light.main"
                  }
              }}
              disablePadding
            >
              <ListItemButton role={undefined} component={Link} to="/company/triggers" dense>
                <ListItemIcon sx={{ color: "inherit"  }}>
                  <ContactMail  fontSize="small"/>
                </ListItemIcon>
                <ListItemText  primary="Triggers" />
              </ListItemButton>
            </ListItem>

        </List>
      </nav>
     
    </Box>
  );
}