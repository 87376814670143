import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link, useParams, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import Microsoft365Menu from 'components/Dashboards/SideMenus/Microsoft365Menu';
import AzureUsersList from './AzureUsersList';
import AzureSignInsList from './AzureSignInsList';
import MailboxStorage from '../Microsoft365/Charts/MailboxStorage';
import EmailActivity from '../Microsoft365/Charts/EmailActivity';
import UserMailboxActivity from '../Microsoft365/Charts/UserMailboxActivity';
import SharePointUsage from '../Microsoft365/Charts/SharePointUsage';
import OneDriveActivity from '../Microsoft365/Charts/OneDriveActivity';
import MessageRules from '../Microsoft365/MessageRulesList';
import OneDriveUsageDetail from '../Microsoft365/Charts/OneDriveUsageDetail';
import OneDriveFileCountDetail from '../Microsoft365/Charts/OneDriveFileCountDetail';

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function AzureUser() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [signins, setSignins] = useState([]);
    const [emailActivity, setEmailActivity] = useState([]);
    const [mailboxDetail, setMailboxDetail] = useState([]);
    const [sharepointDetail, setSharepointDetail] = useState([]);
    const [onedriveDetail, setOnedriveDetail] = useState([]);
    const [onedriveUsageDetail, setOnedriveUsageDetail] = useState([]);
    const [onedriveFileDetail, setOnedriveFileDetail] = useState([]);
    const [messageRules, setMessageRules] = useState([]);
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page= searchParams.get("page")
    const { user_id } = useParams();
    const filters = [];

    searchParams.forEach((value, key) => {
        if ( key === "filter" ) {
            filters.push(value)
        }
    })

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    
    useEffect(() => {
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json()),
            fetch(API_URL + 'azure/sign-ins/user/'+user_id+'/failed', {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/email/activity-user-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/mailbox/usage-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/sharepoint/activity-user-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/onedrive/activity-user-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/onedrive/usage-account-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/onedrive/file-count-detail/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/message-rules/'+user_id, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'azure/user/'+user_id, {headers: authHeader() }).then( res => res.json())
        ])
        .then( (res) => {
            setCloudkeys(res[1]) 
            setSignins(res[2])
            setEmailActivity(res[3].reverse())
            setMailboxDetail(res[4].reverse())
            setSharepointDetail(res[5])
            setOnedriveDetail(res[6])
            setOnedriveUsageDetail(res[7])
            setOnedriveFileDetail(res[8])
            setMessageRules(res[9])
            setUser(res[9])
            setLoading(false)
        })
    }, []);
    document.title = "Azure User "+ user.display_name
      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {

        return (
            <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={9} lg={9}>
                                <Breadcrumbs aria-label="breadcrumb" >
                                    <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                    <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                    <Link to="/azure/users"><Typography color="text.primary">Azure Users</Typography></Link>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Typography variant="h4">{user.display_name} <Typography variant="caption" component={Link} to={`/azure/users/${user_id}/logs`} color="darkgreen.main">View User Logs</Typography></Typography>
                            </Grid>
                            <Grid item xs={12} md={9} lg={9}>
                                { signins.length > 0 ? 
                                        <Grid item xs={12} md={12} mb={2} >
                                            
                                                <AzureSignInsList signins={signins} title={"Recent Sign In Failures"} filters={filters} />
                                                <Typography variant='caption' color="darkgreen.main" component={Link} to={`/azure/users/${user_id}/signins`}>View all sign ins</Typography>
                                        </Grid>
                                : "" }
                                { mailboxDetail.length > 0 ?
                                        <Grid item xs={12} md={12} mb={2} >
                                            <Paper
                                                sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: 300,
                                                }}
                                            >
                                                <MailboxStorage data={mailboxDetail} />
                                            </Paper>
                                        </Grid>
                                : "" }
                                { emailActivity.length > 0 ? 
                                        <Grid item xs={12} md={12} mb={2} >
                                            <Paper
                                                sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: 300,
                                                }}
                                            >
                                                <EmailActivity data={emailActivity} />
                                            </Paper>
                                        </Grid>
                                : "" }
                                { mailboxDetail.length > 0 ?
                                        <Grid item xs={12} md={12} mb={2} >
                                            <Paper
                                                sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: 300,
                                                }}
                                            >
                                                <UserMailboxActivity data={mailboxDetail} />
                                            </Paper>
                                        </Grid>
                                    : "" }
                                { sharepointDetail.length > 0 ?
                                        <Grid item xs={12} md={12} mb={2} >
                                            <Paper
                                                sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: 300,
                                                }}
                                            >
                                                <SharePointUsage data={sharepointDetail} />
                                            </Paper>
                                        </Grid>
                                    : ""}
                                { onedriveDetail.length > 0 ? 
                                        <Grid item xs={12} md={12} mb={2} >
                                            <Paper
                                                sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: 300,
                                                }}
                                            >
                                                <OneDriveActivity data={onedriveDetail} />
                                            </Paper>
                                        </Grid>
                                    : "" }
                                    { onedriveUsageDetail.length > 0 ?
                                        <Grid item xs={12} md={12} mb={2} >
                                        <Paper
                                            sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 300,
                                            }}
                                        >
                                            <OneDriveUsageDetail data={onedriveUsageDetail} />
                                        </Paper>
                                    </Grid>
                                    : ""}
                                    { onedriveFileDetail.length > 0 ?
                                        <Grid item xs={12} md={12} mb={2} >
                                        <Paper
                                            sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 300,
                                            }}
                                        >
                                            <OneDriveFileCountDetail data={onedriveFileDetail} />
                                        </Paper>
                                    </Grid>
                                    : ""}
                                {messageRules.length > 0 ?
                                <Grid item xs={12} md={12} mb={2} >
                                    <Paper
                                        sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minheight: 300,
                                        }}
                                    >
                                        <MessageRules rules={messageRules} />
                                    </Paper>
                                </Grid> : "" }
                                
                                

                            
                            </Grid>

                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    }
}
