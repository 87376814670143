import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { Lock, LockOpen, KeyboardArrowUp, KeyboardArrowDown, BorderBottom, FilterAlt, Block, Filter } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';


function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

function formatClock(date) {
    const clock = new Date(date)
    return clock.toLocaleString()
}


function Row({row}) {
    const [open, setOpen] = useState(false);
    const [filterLog, setFilterLog] = useSearchParams();
  

    const onFilterChange = (event) => {
        event.preventDefault();
        if ( filterLog.get("filter") ) {
            let allFilters = filterLog.getAll("filter")
            console.log(allFilters)
            if ( allFilters.indexOf(event.currentTarget.id ) > -1 ) {
                setFilterLog(filterLog)
            } else {
                filterLog.append("filter", event.currentTarget.id)
                setFilterLog(filterLog)
            }
        } else {
            if ( filterLog.get("page") || filterLog.get("from") || filterLog.get("to")) {
                filterLog.append("filter", event.currentTarget.id)
            } else {
                setFilterLog({filter: event.currentTarget.id})
            }
        }
    }

    return (
      <Fragment>
       
        <TableRow
            key={row.signin_id}
            id={`row-`+row.signin_id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: "0px" }}
            > 
                <TableCell style={{ border: '0px'}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} > 
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ borderBottom: "0px" }} component="th" scope="row"><Typography variant="caption">{row.user_principal.length > 35 ? <Tooltip title={row.user_principal}>{row.user_principal.substring(0,32)}...</Tooltip> : row.user_principal}</Typography></TableCell>
                <TableCell sx={{ borderBottom: "0px" }}  align="left"><Typography variant="caption">{row.city}</Typography></TableCell>
                <TableCell sx={{ borderBottom: "0px" }}  align="right"><Typography variant="caption">{row.state}</Typography></TableCell>
                <TableCell sx={{ borderBottom: "0px" }}  align="right"><Typography variant="caption">{row.country}</Typography></TableCell>
                <TableCell sx={{ borderBottom: "0px" }}  align="right"><Typography variant="caption">{formatClock(row.created)}</Typography></TableCell>
                <TableCell sx={{ borderBottom: "0px" }}  align="right"><Typography variant="caption">{row.error_code === 0 ? <LockOpen sx={{ fontSize: "small" }} color="primary" /> : <Lock sx={{ fontSize: "small" }} color="error" />}</Typography></TableCell>
            </TableRow>
            <TableRow>
                <TableCell />
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "" }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box >
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}><Typography variant="caption">Login Time:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.created}</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell  colSpan={2}><Typography variant="caption">User:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.user_principal}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"user-principal_eq_"+row.user_principal} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"user-principal_ne_"+row.user_principal} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}><Typography variant="caption">Device:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.display_name}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"display-name_eq_"+row.display_name} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"display-name_ne_"+row.display_name    } />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">Device OS:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.operating_system}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"operating-system_eq_"+row.operating_system} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"operating-system_ne_"+row.operating_system} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">Browser:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.browser}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"browser_eq_"+row.browser} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"browser_ne_"+row.browser} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  colSpan={2}><Typography variant="caption">App:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.app_id}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"app-id_eq_"+row.app_id} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"app-id_ne_"+row.app_id} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">IP Address:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.ip_address}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"ip-address_eq_"+row.ip_address} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"ip-address_ne_"+row.ip_address} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  colSpan={2}><Typography variant="caption">Is Interactive:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.is_interactive === true ? "True" : "False"}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"is-interactive_eq_"+row.is_interactive} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"is-interactive_ne_"+row.is_interactive} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">City:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.city}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"city_eq_"+row.city} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"city_ne_"+row.city} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">State:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.state}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"state_eq_"+row.state} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"state_ne_"+row.state} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">Country:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.country}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"country_eq_"+row.country} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"country_ne_"+row.country} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} ><Typography variant="caption">Status:</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{row.error_code === 0 ? <LockOpen sx={{ fontSize: "small" }} color="primary" /> : <Lock sx={{ fontSize: "small" }} color="error" />}</Typography></TableCell>
                                    <TableCell sx={{ cursor: "pointer"}}>
                                        <Typography variant="caption" ><FilterAlt color="darkgreen" sx={{ fontSize: "small" }} onClick={(e) => onFilterChange(e)} id={"error-code_eq_"+row.error_code} /> </Typography>
                                        <Typography variant="caption" ><Block color="error" sx={{ fontSize: "small"}} onClick={(e) => onFilterChange(e)} id={"error-code_ne_"+row.error_code} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: "0px" }}  colSpan={2} ><Typography variant="caption">Status Detail</Typography></TableCell>
                                    <TableCell sx={{ borderBottom: "0px" }} ><Typography variant="caption">{row.reason}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
      </Fragment>
    );
  }
  

export default function AzureSignInsList({signins, title, filters}) {
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();
    const [open, setOpen] = useState(false);
    const [filterLog, setFilterLog] = useSearchParams();
  

    function removeFilter(event) {
        let allFilters = filterLog.getAll("filter")
        if ( allFilters.indexOf(event) > -1 ) {
            filterLog.delete("filter", event)
            setFilterLog(filterLog)
        }
        /* if ( filterLog.get("filter") ) {
            let allFilters = filterLog.getAll("filter")
            if ( allFilters.indexOf(event.currentTarget.id ) > -1 ) {
                console.log("Filter found")
            } else {
                filterLog.append("filter", event.currentTarget.id)
                setFilterLog(filterLog)
            }
        } else {
            setFilterLog({filter: event.currentTarget.id})
        } */
        console.log(event)
    }
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleString()
      }

    return (
        <TableContainer component={Paper}>
                { filters.length > 0 ?
                <Grid item xs={12} >
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: "100%",
                    }}
                >
                    <Stack direction="row" spacing={1}>
                    {filters.map((f) =>
                        <Chip label={f} onDelete={() => removeFilter(f)} />
                    )}
                    </Stack>   
                </Paper>
            </Grid>
            : "" }
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="signins-table">
                <TableHead>
                    <TableRow><TableCell colSpan={5}>
                        <Typography variant="h6">{title}</Typography></TableCell></TableRow>
                    <TableRow>
                        <TableCell />
                        <TableCell>User Principal</TableCell>
                        <TableCell align="left">City</TableCell>
                        <TableCell align="right">State</TableCell>
                        <TableCell align="center">Country</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {signins.map((row) => (
                            <Row key={row.signin_id} row={row} />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>


    );
}
