import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block, DeleteForever, MarkChatRead } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Checkbox from '@mui/material/Checkbox';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function TriggerList({triggers}) {
    function printJSON(data) {
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }

    function updateTrigger(id) {
        console.log(id)
        const ck = document.getElementById('check-'+id)
        
        fetch(API_URL + "azure/trigger/update", {method: 'post', headers: authHeader(), body: JSON.stringify({"trigger_id": id})} )
        .then( (res) => {
          
        })
        .catch( err => {
            console.log(err)
        })
        
      }

        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small" id="item-table">
                    <TableHead>
                       
                        <TableRow>
                            <TableCell>Tenant</TableCell>
                            <TableCell align="left">Item</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell align="left">Enabled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {triggers.map((row) => (
                            <>
                                <TableRow
                                    key={row.id}
                                    >
                                    <TableCell component="th" scope="row"><Typography variant="caption">{row.item.params}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{row.item.item_key.name}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{row.item.item_key.description}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{row.item.item_key.cloud}</Typography></TableCell>
                                    <TableCell align="left">
                                        <Typography variant='caption' color="grey.600" >
                                            { row.enabled 
                                            ? <Checkbox size="small" id={`check-${row.id}`} checked onChange={() => {updateTrigger(row.id)}} />
                                            : <Checkbox size="small" id={`check-${row.id}`} onChange={() => {updateTrigger(row.id)}} />
                                            }
                                            
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  align="right">Expression</TableCell>
                                    <TableCell colSpan={2} align="right"><Typography variant="caption">{printJSON(row.expression)}</Typography></TableCell>
                                    <TableCell />
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        );
}
