import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import colors from 'assets/theme/base/colors';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import SubscriptionsList from './SubscriptionsList';
import CompanyMenu from '../SideMenus/CompanyMenu';


const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
 });

export default function Home() {
    const [open, setOpen] = useState(true);
    const [company, setCompany] = useState([])
    const [loading, setLoading] = useState(true);
    const [alertCount, setAlertCount] = useState();
    const [alertList, setAlertList] = useState([]);
    const [users, setUsers] = useState([]);
    const [itemCount, setItemCount] = useState();
    const [triggerCount, setTriggerCount] = useState();
    const [subServices, setSubServices] = useState([]);
    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'dashboard/services', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'dashboard/alerts', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'alerts/count', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'subs/users', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'item-count', {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL+'trigger-count', {headers: authHeader() }).then(res => res.json()),

        ])
        .then( (res) => {
          setSubServices(res[1]);
          setAlertList(res[2])
          setAlertCount(res[3])
          setUsers(res[4])
          setItemCount(res[5])
          setTriggerCount(res[6])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
        
      }, []);
      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else if ( 'short_list' in subServices ) {
        if  ( subServices.short_list.includes("AZR") ) {
          nav("/azure/365")
        }
    } else {
      
        return (
          <ThemeProvider theme={defaultTheme}>
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 4, mb: 4 }}>
                      <Grid item xs={12} md={6} >
                        <SubscriptionsList subscriptions={subServices.services} count={subServices.services.length} />
                      </Grid>
                      <Grid item xs={12} md={2} >
                          <Paper
                          sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              height: "100%",
                          }}
                          >
                            <CompanyMenu />
                          </Paper>
                      </Grid>
                      
                    </Grid>
                </Box>
              </ThemeProvider>
        );
    }
}
