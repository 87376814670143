import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, CircularProgress, Checkbox } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import AzureCloudSelect from 'components/Dashboards/Azure/AzureCloudSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from "moment";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import EmailActivityList from './EmailActivityList';


var newDate = new Date()


const defaultTheme = createTheme({
    palette: {...colors},
});

export default function MS365EmailActivityByUser() {
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState([]);
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [urlQuery, setUrlQuery] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get("page")
    const [reportDate, setReportDate] = useState(dayjs(new Date()));
    const licensed = searchParams.get("licensed")
    const [checked, setChecked] = useState([true, false]);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [maxDate, setMaxDate] = useState();

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    useEffect(() => {
        document.title = 'Azure Users';
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'reports/azure/ms365/email-activity-by-user?' + searchParams, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json())
        ])
        .then( (res) => {
            setActivity(res[1]["data"])
            setNumPages(res[1]["num_pages"])
            setHasNext(res[1]["has_next"])
            setHasPrev(res[1]["has_previous"])
            setMaxDate(res[1]["max_date"])
            setCloudkeys(res[2]) 
            
            setLoading(false)
        })
       
    }, [searchParams]);

    const updateDate = (() => {
        setButtonLoading(true)
        searchParams.set("report_date", reportDate.unix())
        setSearchParams(searchParams)
               
        setButtonLoading(false)
      })
    
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
    }

    const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        setSearchParams({"page": value})
        setLoading(false)
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }

     const handleChange = (event) => {
        console.log(event.target.checked)
        setChecked([event.target.checked, event.target.checked]);
        setSearchParams({licensed: event.target.checked})
      };

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Typography color="darkgreen.main">Reports</Typography>
                                <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                <Typography color="text.primary">Azure Users</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <Grid >
                                <EmailActivityList activity={activity}  />
                                <br />
                                {numPages > 0 ? <ShowPagination /> : "" }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Select report date" maxDate={dayjs(maxDate)} disableFuture onChange={setReportDate} />
                                        </LocalizationProvider>
                                        {buttonLoading ? <Button sx={{ mt: 2}} variant="contained" size="small" >Loading</Button> : <Button sx={{ mt: 2}} variant="outlined" size="small" onClick={updateDate}>Update</Button> }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="caption" >A seven day aggregate of email activity up to the selected report date on a per user basis. 
                                            The report defaults to the latest available date.</Typography>
                                        
                                    </Paper>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>

        );
    }
}
