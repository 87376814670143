import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Flag, EmojiFlags, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { forwardRef } from "react";  
import CommentIcon from '@mui/icons-material/Comment';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AzureLogComments from './AzureLogComments';
import Backdrop from '@mui/material/Backdrop';



function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

function formatClock(date) {
    const clock = new Date(date)
    return clock.toLocaleString()
}
function printJSON(data) {
    return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
}
function AzureADRowHeader(props) {
    return (
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.ResultStatus}</TypoLink></TableCell>
            
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
    );
}

function SharePointRowHeader(props) {
    return (
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            > 

                <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
                <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
                <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
                <TableCell align="left"><TypoLink>{row.log_data.ItemType}</TypoLink></TableCell>
                {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
                <TableCell align="left">
                    <Typography  variant="caption" >
                            <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                    </Typography>
                </TableCell>
                <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>        
            </TableRow>
    )
}

function ExchangeRowHeader(props) {
    return (
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
    )
}

function GeneralRowHeader(props) {
    return (
        <TableRow
        key={row.log_id}
        id={`row-`+row.log_id}
        
        > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
    )
}


function AzureADRow(props) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])

    const fetchComments = () =>  {
        fetch(API_URL+'azure/management/audit/comments?log_id='+row.log_id, {headers: authHeader() }).then( res => res.json() )
        .then( res => {
            setComments(res)
            setOpen(true);
    
        })
        .catch(err => {
            console.log(err)
        })
    }
    const TypoLink = forwardRef (
        (
            {children, ...rest },
            ref
        ) => (
            <Typography 
                {...rest}
                ref={ref}
                onClick={fetchComments}  
                variant="caption"
                sx={{ cursor: "pointer", textDecoration: "underline"}}
                
            >
                {children}
            </Typography>
        )
    
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: "90%",
        width: "75%",
    };
    return (
      <Fragment>
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.ResultStatus}</TypoLink></TableCell>
            
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
        <Fade in={open}>
            <Box sx={style}>
                <Table size="small" aria-label="purchases">
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Created</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.created}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">User ID:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserId}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">User Key:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserKey}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">User Type:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserType}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Client IP:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ClientIP}</Typography></TableCell>
                    </TableRow>
                   
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Operation:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Operation}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Result Status:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ResultStatus}</Typography></TableCell>
                    </TableRow>
                    { row.log_data.LogonError ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Logon Error</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.LogonError)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.ExtendedProperties ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Extended Properties</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ExtendedProperties)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.ModifiedProperties ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Modified Properties</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ModifiedProperties)}</Typography></TableCell>
                        </TableRow>
                    : "" }
                    { row.log_data.Actor ?
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Actor</Typography></TableCell>
                        <TableCell><Typography variant="caption">{printJSON(row.log_data.Actor)}</Typography></TableCell>
                    </TableRow> 
                    : "" }
                    { row.log_data.Target ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Target</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.Target)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.DeviceProperties ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Device Properties</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.DeviceProperties)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                </Table>
                <AzureLogComments comments={comments} log_id={row.log_id} />
            </Box>
        </Fade>
        </Modal>
      </Fragment>
    );
  }
  

function AzureGeneralRow(props) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])

    const fetchComments = () =>  {
        fetch(API_URL+'azure/management/audit/comments?log_id='+row.log_id, {headers: authHeader() }).then( res => res.json() )
        .then( res => {
            setComments(res)
            setOpen(true);
    
        })
        .catch(err => {
            console.log(err)
        })
        console.log(comments)
    }
    const TypoLink = forwardRef (
        (
            {children, ...rest },
            ref
        ) => (
            <Typography 
                {...rest}
                ref={ref}
                onClick={fetchComments}  
                variant="caption"
                sx={{ cursor: "pointer", textDecoration: "underline"}}
                
            >
                {children}
            </Typography>
        )
    
    );
    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: "90%",
        minWidth: "50%",
    };
    return (
      <Fragment>
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
        <Fade in={open}>
            <Box sx={style}>
                <Table size="small" aria-label="purchases">
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Created</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.created}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">User ID:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserId}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">User Key:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserKey}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">User Type:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserType}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Client IP:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ClientIP}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Workload:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Workload}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Operation:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Operation}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Message ID:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.MessageId}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Communication Type:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.CommunicationType}</Typography></TableCell>
                    </TableRow>
                    { row.log_data.MessageURLs ?
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Message URLs:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{printJSON(row.log_data.MessageURLs)}</Typography></TableCell>
                    </TableRow>
                    : "" }
                    { row.log_data.ExtraProperties ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Extra Properties</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ExtraProperties)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.ParticipantInfo ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Participant Info</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ParticipantInfo)}</Typography></TableCell>
                        </TableRow>
                    : "" }
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Old Value</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.OldValue}</Typography></TableCell>
                    </TableRow>
                </Table>
                <AzureLogComments comments={comments} log_id={row.log_id} />
            </Box>
           
        </Fade>
        </Modal>
      </Fragment>
    );
  }


function AzureExchangeRow(props) {
    console.log(props)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])


    const fetchComments = () =>  {
        fetch(API_URL+'azure/management/audit/comments?log_id='+row.log_id, {headers: authHeader() }).then( res => res.json() )
        .then( res => {
            setComments(res)
            setOpen(true);
    
        })
        .catch(err => {
            console.log(err)
        })
        console.log(comments)
    }
    const TypoLink = forwardRef (
        (
            {children, ...rest },
            ref
        ) => (
            <Typography 
                {...rest}
                ref={ref}
                onClick={fetchComments}  
                variant="caption"
                sx={{ cursor: "pointer", textDecoration: "underline"}}
                
            >
                {children}
            </Typography>
        )
    
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: "90%",
        width: "75%"
    };
    return (
      <Fragment>
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>
        </TableRow>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
        <Fade in={open}>
            <Box sx={style}>
                <Table size="small" aria-label="purchases">
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Created</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.created}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">User ID:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserId}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">User Key:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserKey}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">User Type:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserType}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Client IP:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ClientIP}</Typography></TableCell>
                    </TableRow>
                   
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Operation:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Operation}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Result Status:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ResultStatus}</Typography></TableCell>
                    </TableRow>
                    
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Public Folder Name:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Workload}</Typography></TableCell>
                    </TableRow>
                    { row.log_data.Item ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Item</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.Item)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.AffectedItems ?
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Affected Items</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.AffectedItems)}</Typography></TableCell>
                        </TableRow>
                    : "" }
                    { row.log_data.DestFolder ?
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Destination Folder</Typography></TableCell>
                        <TableCell><Typography variant="caption">{printJSON(row.log_data.DestFolder)}</Typography></TableCell>
                    </TableRow> 
                    : "" }
                    { row.log_data.Folder ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Folder</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.Folder)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.ModifiedProperties ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Modified Properties</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ModifiedProperties)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.Parameters ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Parameters</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.Parameters)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">External Access</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.ExternalAccess)}</Typography></TableCell>
                        </TableRow>
                    { row.log_data.OrganizationName ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Organization Name</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.OrganizationName)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                    { row.log_data.OriginatingServer ? 
                        <TableRow>
                            <TableCell colSpan={2}><Typography variant="caption">Originating Server</Typography></TableCell>
                            <TableCell><Typography variant="caption">{printJSON(row.log_data.OriginatingServer)}</Typography></TableCell>
                        </TableRow>
                    : ""}
                </Table>
                <AzureLogComments comments={comments} log_id={row.log_id} />
            </Box>
        </Fade>
        </Modal>
      </Fragment>
    );
  }
  

function AzureSharePointRow(props) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])

    const fetchComments = () =>  {
        fetch(API_URL+'azure/management/audit/comments?log_id='+row.log_id, {headers: authHeader() }).then( res => res.json() )
        .then( res => {
            setComments(res)
            setOpen(true);
    
        })
        .catch(err => {
            console.log(err)
        })
        console.log(comments)
    }
    const TypoLink = forwardRef (
        (
            {children, ...rest },
            ref
        ) => (
            <Typography 
                {...rest}
                ref={ref}
                onClick={fetchComments}  
                variant="caption"
                sx={{ cursor: "pointer", textDecoration: "underline"}}
                
            >
                {children}
            </Typography>
        )
    
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        height: "90%",
        overflow: 'scroll',
    };
    return (
      <Fragment>
        <TableRow
            key={row.log_id}
            id={`row-`+row.log_id}
            > 

            <TableCell component="th" scope="row"><TypoLink>{formatClock(row.created)}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Workload}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.Operation}</TypoLink></TableCell>
            <TableCell align="left"><TypoLink>{row.log_data.ItemType}</TypoLink></TableCell>
            {type === "user" ? "" : <TableCell align="left"><TypoLink>{row.log_data.UserId.length > 30 ? <Tooltip title={row.log_data.UserId}>{row.log_data.UserId.substring(0,30)}...</Tooltip>: row.log_data.UserId}</TypoLink></TableCell> }
            <TableCell align="left">
                <Typography  variant="caption" >
                        <Flag onClick={() => setFlag(row.log_id)} color={row.flagged ? "error" : "lightgrey"} sx={{ fontSize: "small", cursor: 'pointer' }} id={"flag-"+row.log_id} />
                </Typography>
            </TableCell>
            <TableCell align="left">{ row.comment_count > 0 ? <CommentIcon sx={{ fontSize: "medium" }} color="primary" /> : <CommentIcon sx={{ fontSize: "medium" }} />}</TableCell>        </TableRow>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                timeout: 500,
                },
            }}
        >
        <Fade in={open}>
            <Box sx={style}>
                <Table size="small" aria-label="purchases">
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Login Time:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.created}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">User:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserId}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><Typography variant="caption">Site URL:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.SiteUrl}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">User Key:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserKey}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Client IP:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ClientIP}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Item Type:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.ItemType}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} ><Typography variant="caption">Workload:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Workload}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Operation:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Operation}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">User Agent:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.UserAgent}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Browser:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Browser}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Browser Version:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.BrowserVersion}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Source:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.EventSource}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Operation:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.Operation}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Source File:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.SourceFileName}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">FIle Sync Bytes Committed:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.FileSyncBytesCommitted}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Relative URL:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.SourceRelativeUrl}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">File Extension:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.SourceFileExtension}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell  colSpan={2}><Typography variant="caption">Device Display Name:</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.log_data.DeviceDisplayName}</Typography></TableCell>
                    </TableRow>
                </Table>
                <AzureLogComments comments={comments} log_id={row.log_id} />

            </Box>
        </Fade>
        </Modal>
      </Fragment>
    );
  }
  
export default function AzureUserManagementLogsList({logs, type}) {
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();

    
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleString()
      }
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="logs-table">
                <TableHead>
                    <TableRow><TableCell colSpan={5}>
                        <Typography variant="h6">Management Logs</Typography></TableCell></TableRow>
                    <TableRow>
                        <TableCell />
                        <TableCell>Created</TableCell>
                        <TableCell align="left">Workload</TableCell>
                        <TableCell align="left">Operation</TableCell>
                        <TableCell align="left">Item Type</TableCell>
                        {type === "user" ? "" : <TableCell align="left">User ID</TableCell>}
                        {/* <TableCell align="left">Client IP</TableCell> */}
                        <TableCell align="left"><Flag sx={{ fontSize: "medium" }} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {logs.map((row) => {
                    /* row.content_type === "Audit.Exchange" ? ( <AzureExchangeRow key={row.id} row={row} type={type} />)
                    : ( <AzureSharePointRow key={row.id} row={row} type={type} /> ) */
                    switch(row.content_type) {
                        case "Audit.Exchange":
                            return ( <AzureExchangeRow key={row.id} row={row} type={type} /> )
                        case "Audit.SharePoint":
                            return ( <AzureSharePointRow key={row.id} row={row} type={type} />)
                        case "Audit.General":
                            return ( <AzureGeneralRow key={row.id} row={row} type={type} />)
                        case "Audit.AzureAD":
                            return ( <AzureADRow key={row.id} row={row} type={type} /> )
                    }
                })}
                </TableBody>
            </Table>
        </TableContainer>


    );
}
