import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import trawlLogo from "assets/images/logos/trawl logo large 2022.png";
import Copyright from 'components/Copyright/Copyright';
import colors from "assets/theme/base/colors";
import { Link , useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import { CircularProgress } from '@mui/material';

const defaultTheme = createTheme({
  palette: {...colors}
});


export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successAlert, setSuccessAlert ] = useState(false);
  const nav = useNavigate();
  const handleSubmit = (event) => {
    setAlert(false)
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if ( data.get('password') !== data.get('password-confirm')) {
      setAlert(true)
      setAlertMessage("Passwords do not match. Please try again.")
    } else {
      let opts = { method: 'POST', body: JSON.stringify({
          'first_name': data.get('firstName'), 
          'last_name': data.get('lastName'), 
          'company_name': data.get('company'), 
          'email': data.get('email'), 
          'password': data.get('password')})};
      Promise.all([ 
        fetch(API_URL + 'subs/register', opts, {headers: authHeader() })
        .then( (res) => {
          if ( res.status === 200 ) {
            nav("/sign-in?registration=success")
          } else if ( res.status === 409 ) {
            setAlertMessage("This company is already registered. Please contact us if this is an error.")
          } else if ( res.status === 500 ) {
            setAlertMessage("We've encountered an error. Please try again later.")
          } else if ( res.response.status === 424 ) {
            setAlertMessage("Company information saved, but user registration failed. Please contact us to report the error.")
          } else {
            setAlert(true)
            setAlertMessage("An unknown error has occurred. Please contact support at support@trawlai.com")
          }
          setAlert(true)
          setLoading(false)
        })
        .catch( err => {
          setAlert(true)
          setAlertMessage("An unknown error has occurred. Please contact support at support@trawlai.com")
        })
      ]);
    }
    setLoading(false)
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={trawlLogo} alt="Trawl AI" width="450px" />
          <Typography component="h1" variant="h5" mt={2}>
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            { alert ? 
                <Grid item xs={12}><Alert severity='error'  action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}>{alertMessage}</Alert></Grid>
                : ""}
                { successAlert ? 
                <Grid item xs={12}><Alert severity='success'  
                sx={{ mb: 2 }}>{alertMessage}</Alert></Grid>
                : ""}
              <Grid item xs={12} sm={6}>
               
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Company Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  autoComplete="company"
                  />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password-confirm"
                  label="Confirm Password"
                  type="password"
                  id="password-confirm"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="darkgreen" checked />}
                />
                <Typography component={Link} target="_blank" color="darkgreen.main" to="/terms-of-service" variant="caption">I agree to the terms of service</Typography>
              </Grid>
            </Grid>
            {loading ?
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="darkgreen"
                disabled
              >
                <Typography color="light.main"><CircularProgress color="dark" size="1rem" /></Typography>
              </Button>
            :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="darkgreen"
              >
                <Typography color="light.main">Sign Up</Typography>
              </Button>
            }
            <Grid container justifyContent="center">
              <Grid item>
                <Typography component={Link} href="/sign-in" variant="body2" color="darkgreen.main">
                  Already have an account? Sign in
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}