import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from '../SideMenus/CompanyMenu';
import Pagination from '@mui/material/Pagination';
import TriggerList from './TriggerList';
import AzureCloudSelect from '../Azure/AzureCloudSelect';

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function Items() {
    const [loading, setLoading] = useState(true);
    const [triggers, setTriggers] = useState([]);
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [urlQuery, setUrlQuery] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page= searchParams.get("page")
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }

    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }

    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL + 'azure/triggers', {headers: authHeader() }).then( res => res.json()),
          fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json())
        ])
        .then( (res) => {
          setTriggers(res[1])
          setNumPages(res[1]["num_pages"])
          setHasNext(res[1]["has_next"])
          setHasPrev(res[1]["has_previous"])
          setCloudkeys(res[2])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, [searchParams]);

      console.log(triggers.data)
      function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
      }

      const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        setSearchParams({"page": value})
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }
    if ( loading === true ) {
        return (
            <Container maxWidth="lg" sx={{ mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h4"><CircularProgress color="dark" size="2rem" /></Typography>
                    </Grid>
                </Grid>
            </Container>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                                <Typography color="text.primary">Triggers</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <TriggerList triggers={triggers.data} />
                            <Grid mt={5} >
                                {numPages > 0 ? <ShowPagination /> : "" }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} maxHeight={"300px"}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                            >
                                <CompanyMenu />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
                </Box>

        );
    }
}
