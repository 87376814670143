import {useState, useEffect}  from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { IconButton, Typography } from '@mui/material';
import {  createTheme, ThemeProvider } from '@mui/material/styles';

import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import colors from 'assets/theme/base/colors';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none' 
      },
    },
   });
  

function renderScore(score, max) {
    let pct = score / max * 100
    pct = Math.round(pct).toFixed(2)
    if ( pct >= 80) {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="success">{pct}%</Typography>
        <Typography variant="caption" color="dark">Great work!</Typography>
        </Box>)
    }  else if ( pct <= 67 ) {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="error">{pct}%</Typography>
        <Typography variant="caption" color="dark">Needs some work.</Typography></Box>)
    } else {
      return ( <Box>
        <Typography sx={{ fontSize: 65}} variant="h1" color="warning">{pct}%</Typography>
        <Typography variant="caption" color="dark">You're almost there.</Typography></Box>)
    }
    
  }

export default function AzureAlerts({count}) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (count) {
            setLoading(false)
        }
    })
    
    if ( loading === false ) {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Card sx={{ minHeight: "100%" }}>
                    <CardContent>
                            <Typography  gutterBottom>
                                Azure Alerts
                            </Typography>
                            <Typography color="primary" variant="h1" sx={{ fontSize: 65}} >{count}</Typography>
                    </CardContent>
                    <CardActions>
                            <Typography color="darkgreen.main" mt="2" component={Link} variant="caption" to="/company/alerts">View Alerts</Typography>
                    </CardActions>
                    
                </Card>
            </ThemeProvider>
        )  
    }
}
