import { Component, useEffect, useState } from "react";
import { API_URL } from "constants/api";
import authHeader from "./DataService";
import axios from "axios";

class AuthService {
    

    getToken() {
        return JSON.parse(localStorage.getItem('token'));
    }
    async login(email, password) {
        try {
            const response = await axios.post(API_URL + "subs/login/", { email, password });
            if (response.data.access) {
                localStorage.setItem("token", response.data.access);
                localStorage.setItem("refresh", response.data.refresh);
                
            }
            
            return true;
        } catch (err) {
            if ( err.response.status === 500 ) {
                throw err;
            } else if (err.response.status === 400 ) {
                return err.resopnse.status
            } else {
                return err;
            }
        }
    }
  
    logout() {
        try {
            localStorage.removeItem("token");
            localStorage.remoteItem("cloud_key_azure")
            return true;
        } catch (err) {
            console.log(err)
        }
    }

    register(email, password, company) {
        return axios.post(API_URL + "register", {
            email,
            password,
            company
        });
    }
   isAuthenticated() {
       var token = localStorage.getItem('token')
        if ( typeof token !== 'undefined' || token !== null) {
            return true
        }
        return false

    }

}

export default new AuthService();