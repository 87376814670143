import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function EmailActivityList({activity}) {
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    let date = searchParams.get("report_date")
    
    function bytes(bytes, label) {
        if (bytes === 0) return '';
        if (label === "None") return bytes;
        var s = ['b', 'K', 'M', 'G', 'T', 'P'];
        var e = Math.floor(Math.log(bytes)/Math.log(1024));
        var value = ((bytes/Math.pow(1024, Math.floor(e))).toFixed(2));
        e = (e<0) ? (-e) : e;
        if (label) value += ' ' + s[e];
        return value +'' + s[e];
    }

    function formatClock(date) {
        const clock = new Date(date * 1000)
        return clock.toLocaleDateString("en-US")
      }
    const rows = []
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="alert-table">
                    <TableHead>
                        <TableRow><TableCell colSpan={5}>
                            <Typography variant="h6">Report - Email Activity by User {date ? formatClock(date) : ""}</Typography></TableCell></TableRow>
                        <TableRow>
                            <TableCell>User Principal</TableCell>
                            <TableCell align="left">Report Date</TableCell>
                            <TableCell align="left">Receive Count</TableCell>
                            <TableCell align="left">Read Count</TableCell>
                            <TableCell align="left">Send Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {activity.map((row) => (
                            <TableRow
                           
                            key={row.id}
                            id={`row-`+row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: "none"}}
                            >
                                <TableCell align="left"><Typography variant="caption">{row.user_principal.length > 35 ? <Tooltip title={row.user_principal}>{row.user_principal.substring(0, 35)}...</Tooltip> : row.user_principal}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.report_date}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.receive_count}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.read_count}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="caption">{row.send_count}</Typography></TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>


        );
}
