import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block, DeleteForever, MarkChatRead } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Checkbox from '@mui/material/Checkbox';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function ItemList({items, tenant}) {
    function updateItem(id) {
        const ck = document.getElementById('check-'+id)
        
        fetch(API_URL + "azure/item/update", {method: 'post', headers: authHeader(), body: JSON.stringify({"item_id": id})} )
        .then( (res) => {
          
        })
        .catch( err => {
            console.log(err)
        })
        
      }

        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small" id="item-table">
                    <TableHead>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <Typography variant="overline">Tenant: {tenant}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="center">Service</TableCell>
                            <TableCell align="center">Interval</TableCell>
                            <TableCell align="right">Enabled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {items.map((row) => (
                        <TableRow
                            key={row.item_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell align="left"><Typography variant="caption">{row.item_key.name}</Typography></TableCell>
                            <TableCell align="left"><Typography variant="caption">{row.item_key.description}</Typography></TableCell>
                            <TableCell align="right"><Typography variant="caption">{row.item_key.cloud}</Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption">{row.interval}</Typography></TableCell>
                            <TableCell align="center">
                                <Typography variant='caption' color="grey.600" >
                                    { row.enabled 
                                    ? <Checkbox size="small" id={`check-${row.item_id}`} checked onChange={() => {updateItem(row.item_id)}} />
                                    : <Checkbox size="small" id={`check-${row.item_id}`} onChange={() => {updateItem(row.item_id)}} />
                                    }
                                    
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>


        );
}
