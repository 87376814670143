import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, CircularProgress, Checkbox } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import Microsoft365Menu from 'components/Dashboards/SideMenus/Microsoft365Menu';
import AzureCloudSelect from '../AzureCloudSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import AzureSecureScoreList from './SecureScoreList';

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function AzureSecureScoreView() {
    const [loading, setLoading] = useState(true);
    const [scores, setScores] = useState([]);
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [urlQuery, setUrlQuery] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [cloudKeys, setCloudkeys] = useState([]);
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page= searchParams.get("page")
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }
    useEffect(() => {
        document.title = 'Azure Users';
        setSelectedKey(localStorage.getItem("cloud_key_azure"))
        Promise.all([
            fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            } else if ( res.status === 204 ) {
                setNoResults = true;
            }
            }),
            fetch(API_URL + 'azure/secure-score?cloud_key='+cloudKey, {headers: authHeader() }).then( res => res.json()),
            fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then(res => res.json())
            
        ])
        .then( (res) => {
            setScores(res[1]["control_scores"])
            setNumPages(res[1]["num_pages"])
            setHasNext(res[1]["has_next"])
            setHasPrev(res[1]["has_previous"])
            setCloudkeys(res[2]) 
            setLoading(false)
        })
       
    }, [searchParams]);

    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
    }

    const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        setSearchParams({"page": value})
        setLoading(false)
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }

     const handleChange = (event) => {
        console.log(event.target.checked)
        setChecked([event.target.checked, event.target.checked]);
        setSearchParams({licensed: event.target.checked})
      };

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} lg={9}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                                <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                <Typography color="text.primary">Azure Secure Score</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <AzureCloudSelect keys={cloudKeys} selected={cloudKey} />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                            <AzureSecureScoreList scores={scores} />
                            <Grid mt={5} >
                                {numPages > 0 ? <ShowPagination /> : "" }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} maxHeight={"220px"}>
                            <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: "100%",
                            }}
                            >
                                <Microsoft365Menu />
                            </Paper>
                            <Paper sx={{
                                p: 2,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: "100px",
                            }}>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright sx={{ pt: 4 }} />
                </Box>

        );
    }
}

