import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AzureCloudSelect({keys, selected}) {
  const [cloud, setCloud] = useState('');
  const [loading, setLoading] = useState(true);
  const updateKey = (event) => {
    localStorage.setItem("cloud_key_azure", event.target.value)
    window.location.reload()
  };
  var val = keys.filter(item => item.id === parseInt(selected))[0]
  useEffect(() => {
   
    if (val) {
      setLoading(false)
    }
  })
  
  if ( loading === false ) {
    return (
      <Box>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{val["name"]}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Tenant"
            onChange={updateKey}
          >
              { keys.map((row) => (
                      <MenuItem value={row.id} key={row.id} >{row.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
}