import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';

function preventDefault(event) {
  event.preventDefault();
}

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function NotificationsList({notifications, count, type}) {
    const [updateFailed, setUpdateFailed] = useState();
    const [updateSucceeded, setUpdateSucceeded] = useState();

    function serviceSwitch(param) {
        switch(param) {
          case 'AZR':
            return 'Azure';
          case 'DO':
            return 'DigitalOcean';
          case 'AWS':
            return 'Amazon AWS';
        }
    }
    function updateNotification(id) {
        setUpdateFailed(false)
        setUpdateSucceeded(false)
        fetch(API_URL + "subs/account/notifications?id=" +id,{headers: authHeader()} ).then( res => res.json())
        .then( (res) => {
          if (res.status == "200") {
            setUpdateSucceeded(true)
          } else {
            setUpdateFailed(true)
          }
        })
        .catch( err => {
          setUpdateFailed(true)
        })
      
    }
    const rows = []
        if ( type === 'short' ) {
            notifications.slice(0,5).forEach( res => {
                rows.push(res)
            })
        } else {
            notifications.forEach( res => {
                rows.push(res)
            })
        }
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography variant="h6">Notifications
                                    { (type === 'short') ? <Typography  color="warning.main" paddingLeft={3} variant="caption" component={Link} to="/company/services">view all</Typography> : "" }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell align="right">Tenant</TableCell>
                            <TableCell align="right">Item</TableCell>
                            <TableCell align="right">Rule</TableCell>
                            <TableCell align="right">Enabled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.id}
                           
                            >
                            <TableCell component="th" scope="row">
                                {serviceSwitch(row.trigger_type)}
                            </TableCell>
                            <TableCell align="right">{row.cloud_key}</TableCell>
                            <TableCell align="right">{row.item_description}</TableCell>
                            <TableCell align="right">{row.trigger_description}</TableCell>
                            <TableCell align="right">
                                {row.enabled ? 
                                    <Checkbox defaultChecked onChange={() => updateNotification(row.id)} />
                                :
                                    <Checkbox onChange={() => updateNotification(row.id)} />
                                }</TableCell>
                            </TableRow>
                        ))}
                    {( type === "short" && count > 5) ? <TableCell colSpan={5}>...</TableCell> : ""}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={5}>
                                <Button
                                    color="darkgreen"
                                    type="submit"
                                    fullWidth
                                    variant="text"
                                    size="small"
                                    component={Link}
                                    to="/company/subscriptions/add"
                                >
                                    <Typography >Add Subscription</Typography>
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>


        );
}
