import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ServicesList from './ServicesList';
import Copyright from 'components/Copyright/Copyright';
import trawlLogo from 'assets/images/logos/trawl dark logo med 2022.png'
import { grey, purple, blueGrey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors';
import { Paper, Card, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import SubscriptionsList from './SubscriptionsList';
import SummaryList from './SummaryList';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CompanyMenu from '../SideMenus/CompanyMenu';

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function Services() {
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);
    const nav = useNavigate();
    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
      }
    
    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'subs/services/list', {headers: authHeader() }).then(res => res.json()),

        ])
        .then( (res) => {
          setServices(res[1]);
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
      }, []);
      console.log(services)
     
    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
          <Box
          component="main"
          sx={{
              backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
          }}
          >
            <Container sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9} mb={3}>
                  <Breadcrumbs aria-label="breadcrumb" >
                          <Link to="/app" ><Typography color="darkgreen.main">Dashboard</Typography></Link>
                          <Link to="/company/profile" ><Typography color="darkgreen.main">Company</Typography></Link>
                      <Typography color="text.primary">Services</Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <ServicesList services={services} />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Paper
                  sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                  }}
                  >
                      <CompanyMenu />
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
              </Container>
          </Box>

        );
    }
}
